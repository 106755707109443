/* eslint-disable require-jsdoc */
import { ContentPage } from 'app/components/layout';

import React from 'react';
import { useTranslation } from 'react-i18next';
import UserManagementView from './views/management/ManagementView';

/**
 * @return {JSX.Element}
 */
export const UserManagementPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <ContentPage width={'45vw'} title={t('admin.userManagement.title')}>
            <UserManagementView />
        </ContentPage>
    );
}
