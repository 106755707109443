import React from 'react';

import { Box, Grid, styled, Typography } from '@mui/material';
import { CMSItem, LocalizedTags } from '@oma-kala-shared/core/model';
import { first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ContentPage } from 'app/components/layout';
import { filterCmsItemsByTag, getDefaultTagsets, getLocalizedLinkTag } from 'app/logic/CmsService';
import { selectCmsElements } from 'app/state/cms/cmsSlice';
import { selectIsLoggedIn } from 'app/state/user/userSlice';

import LandingWrapper from '../landing/LandingWrapper';
import { StoreVariantEnum } from 'app/logic/ImageService';
import StoreImageButton from 'app/components/app-links/StoreImageButton';
import StoreQrCode from 'app/components/app-links/StoreQrCode';
import { width } from '@mui/system';
import useScrollToTop from 'app/hooks/useScrollToTop';

const AboutWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const MainWrapper = styled('div')({
    maxWidth: '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    '@media only screen and (min-width: 900px)': {
        textAlign: 'left',
        maxWidth: '1200px',
        justifyItems: 'center',
    },
});

const SplashMessage = styled('div')(({ color }) => ({
    color: color,
    marginBottom: '40px',
    fontSize: '18px',
    fontWeight: 400,
    '@media only screen and (min-width: 768px)': {
        marginBottom: '30px',
    },
}));

/**
 * @return {JSX.Element}
 */
export function AboutPage() {
    const { t, i18n } = useTranslation();
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    useScrollToTop();

    // Dynamic content from CMS to home page
    const elements: CMSItem[] = useSelector(selectCmsElements);
    const tagset: LocalizedTags = getDefaultTagsets();
    const cmsItem: CMSItem | undefined = first(filterCmsItemsByTag(elements, getLocalizedLinkTag(i18n.language, tagset)));
    const storeImageButtonStyle = { height: '50px' };

    const cardWrapperStyle = {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        padding: '10px',
        margin: '10px',
        borderRadius: '5px',
    };

    const aboutWrapperStyle = isLoggedIn
        ? {}
        : {
              background: 'white',
              padding: '2rem',
              margin: '0.5rem auto',
              width: '95%',
          };

    const pageContent = (
        <AboutWrapper sx={aboutWrapperStyle}>
            <MainWrapper>
                <SplashMessage>
                    {cmsItem && (
                        <Typography sx={{ marginBottom: 6 }} fontSize={{ lg: 22, sm: 22 }}>
                            <span dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }}></span>
                        </Typography>
                    )}
                    <Grid container spacing={3}>
                        <Grid item md={12} lg={6} sx={{ textAlign: 'center' }}>
                            <Box sx={cardWrapperStyle}>
                                <Typography sx={{ marginBottom: 6 }} variant="body1">
                                    {t('about.downloadAndroid')}
                                </Typography>
                                <Box sx={{ m: 10 }}>
                                    <StoreQrCode marketplace={StoreVariantEnum.PLAY_STORE} />
                                </Box>
                                <StoreImageButton style={storeImageButtonStyle} marketplace={StoreVariantEnum.PLAY_STORE} />
                            </Box>
                        </Grid>
                        <Grid item md={12} lg={6} sx={{ textAlign: 'center' }}>
                            <Box sx={cardWrapperStyle}>
                                <Typography sx={{ marginBottom: 6 }} variant="body1">
                                    {t('about.downloadIos')}
                                </Typography>
                                <Box sx={{ m: 10 }}>
                                    <StoreQrCode marketplace={StoreVariantEnum.APP_STORE} />
                                </Box>
                                <StoreImageButton style={storeImageButtonStyle} marketplace={StoreVariantEnum.APP_STORE} />
                            </Box>
                        </Grid>
                    </Grid>
                </SplashMessage>
            </MainWrapper>
        </AboutWrapper>
    );

    if (isLoggedIn) {
        return (
            <ContentPage width={'45vw'} title={t('about.pageTitle')}>
                {pageContent}
            </ContentPage>
        );
    } else {
        return <LandingWrapper visibleLoginButton={true} visibleBackButton={true} main={pageContent} />;
    }
}
