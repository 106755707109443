/**
 *
 */
export type StoreLink = [string, string];

/**
 * @return {StoreLink} SyncTokenData
 */
export function useStoreLinks(): StoreLink {
    // Old appstore url https://apps.apple.com/app/omakala/id1587329867
    const appStoreLink = 'https://apps.apple.com/fi/app/omakala/id1587329867';
    const playStoreLink = 'https://play.google.com/store/apps/details?id=fi.luke.omakala';

    return [appStoreLink, playStoreLink];
}
