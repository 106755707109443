import React, { useCallback, useEffect, useState } from 'react';
import MapSearchInput from './MapSearchInput';
import MapSearchResult from './MapSearchResult';
import { useSyncToken } from 'app/hooks';
import { fetchMapLocation } from 'app/state/thunks/fetchMapLocation';
import { useDispatch } from 'react-redux';
import { DEFAULT_SEARCH_GEOMETRY_CRS, GeoJsonPoint, GeocodingFeature } from '@oma-kala-shared/core/model';
import { createPointFromCoordinate, createPointFeature } from '@oma-kala-shared/core/logic';
import { Feature, Map } from 'ol';
import { Point } from 'ol/geom';
import useMapLayer from 'app/hooks/useMapLayer';
import { MapSearchData } from './MapSearchData';
import BaseMapHandler from 'app/logic/BaseMapHandler';

const MapSearchPanel = () => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [searchResult, setSearchResult] = useState<GeocodingFeature[] | null>(null);
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
    const [selectedResult, setSelectedResult] = useState<GeocodingFeature | null>(null);

    useEffect(() => {
        if (!selectedResult || !mapLayer) {
            return;
        }

        if (selectedResult.geometry.coordinates.length >= 2) {
            const convertedPoint: GeoJsonPoint = createPointFromCoordinate(
                selectedResult.geometry.coordinates,
                DEFAULT_SEARCH_GEOMETRY_CRS
            );
            const pointFeature: Feature<Point> = createPointFeature(convertedPoint);
            const featureGeometry = pointFeature.getGeometry();
            if (featureGeometry) {
                mapLayer.setLocation(featureGeometry.getCoordinates());
            }
        }
    }, [selectedResult]);

    const mapHandlerInitializer = useCallback((map: Map) => {
        return new BaseMapHandler(map);
    }, []);

    const mapLayer = useMapLayer(mapHandlerInitializer);

    const [syncToken, fetchingResult] = useSyncToken({
        onSuccess: (response, message) => {
            setSearchResult(response);
            setShowSearchResult(true);
        },
        onError: message => {
            setSearchResult(null);
            setShowSearchResult(true);
        },
    });

    const handleCloseSearchResult = () => {
        setSearchResult(null);
        setShowSearchResult(false);
    };

    const onSearchQueryChange = (searchQuery: string) => {
        setSearchQuery(searchQuery);
        setSearchResult(null);
        setSelectedResult(null);
        dispatch(fetchMapLocation(syncToken, searchQuery));
    };

    const onResultSelected = (selectedResult: GeocodingFeature) => {
        setSelectedResult(selectedResult);
        setShowSearchResult(false);
    };

    const handleSearchDataClose = () => {
        setShowSearchResult(true);
        setSelectedResult(null);
    };

    return (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                zIndex: 2,
            }}
            className="search-panel"
        >
            <MapSearchInput initialQuery={searchQuery} onSearchQueryChange={onSearchQueryChange} showLoadingIcon={fetchingResult} />
            {showSearchResult ? (
                <>
                    <MapSearchResult
                        searchResult={searchResult}
                        isFetchingResult={fetchingResult}
                        onClose={handleCloseSearchResult}
                        onResultSelected={onResultSelected}
                    />
                </>
            ) : (
                <>{selectedResult && <MapSearchData feature={selectedResult} onClose={handleSearchDataClose} />}</>
            )}
        </div>
    );
};

export default MapSearchPanel;
