import { Box, Button, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { capitalize, isExternalIdentityProviderToken } from '@oma-kala-shared/core/logic';
import { ConsentData, TokenData, UpdateUserRequest, UserData } from '@oma-kala-shared/core/model';
import { ContentPage } from 'app/components/layout';
import { useSyncToken } from 'app/hooks';
import { setErrorMessage, setSuccessMessage } from 'app/state/message/messageSlice';
import { updateUser } from 'app/state/thunks/updateUser';
import { selectLoggedInUserData, selectTokenData, selectUserIsAdmin, selectUserIsEditor } from 'app/state/user/userSlice';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getMandatoryOrOptionalTranslation } from '@oma-kala-shared/core/logic/TranslationService';
import PhoneNumberInput from 'app/components/common/inputs/PhoneNumberInput';
import UserConsents, { ActionButtonRendererParams } from 'app/components/profile/sections/UserConsent';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ProfileSectionWrapper } from './ProfileSectionWrapper';
import useAuthData from 'app/hooks/useAuthData';
import SvkInfo from 'app/components/profile/sections/SvkInfo';
import UserRole from 'app/components/profile/sections/UserRole';

/**
 * Create a default update request for the given user data
 * @param {UserData | null} userData
 * @return {UpdateUserRequest}
 */
function createDefaultState(userData: UserData | null): UpdateUserRequest {
    return {
        email: userData?.email ?? '',
        addressStreet: userData?.addressStreet ?? '',
        addressPostalTown: userData?.addressPostalTown ?? '',
        addressPostalCode: userData ? String(userData.addressPostalCode) : '',
        addressMunicipality: userData?.municipality ?? '',
        phoneCountryCode: userData?.phoneCountryCode ?? '',
        phoneNumber: userData?.phone ?? '',
    };
}

export enum ProfileSection {
    PRIMARY_INFORMATION = 'PRIMARY_INFORMATION',
    PASSWORD_CHANGE = 'PASSWORD_CHANGE',
    USER_CONSENTS = 'USER_CONSENTS',
    SVK_INFO = 'SVK_INFO',
    USER_ROLE = 'USER_ROLE',
}

/**
 * @return {JSX.Element}
 */
export function ProfilePage() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { token, userData, isExternalIdpToken } = useAuthData();
    const provider = token?.body.provider ?? 'Omakala';
    const [initialState, setInitialState] = useState<UpdateUserRequest>(createDefaultState(userData));
    const [state, setState] = useState<UpdateUserRequest>(createDefaultState(userData));
    const [dirty, setDirty] = useState(false);
    const [activeSection, setActiveSection] = useState<ProfileSection | null>(ProfileSection.PRIMARY_INFORMATION);
    const [phoneNumber, setPhoneNumber] = useState(userData?.phone ?? '');
    const [phoneNumberCode, setPhoneNumberCode] = useState(userData?.phoneCountryCode ?? '');
    const [userId] = useState(userData?.id ?? '');
    const isAdminUser: boolean = useSelector(selectUserIsAdmin);
    const isEditorUser: boolean = useSelector(selectUserIsEditor);

    const [syncToken, loading] = useSyncToken({
        onSuccess: () => {
            dispatch(setSuccessMessage(t('profile.successMessage')));
        },
        onError: message => {
            dispatch(setErrorMessage(t('profile.errorMessage', { message: message })));
        },
    });

    useEffect(() => {
        setInitialState(createDefaultState(userData));
        setState(createDefaultState(userData));
        setDirty(false);
    }, [userData]);

    const handleSectionToggle = (section: ProfileSection) => {
        if (section === activeSection) {
            setActiveSection(null);
            return;
        }
        setActiveSection(section);
    };

    const onValueChanged = (key: keyof UpdateUserRequest) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState(prev => ({ ...prev, [key]: e.target.value }));
        setDirty(true);
    };

    const updateUserInfo = () => {
        dispatch(updateUser(syncToken, userId, state));
    };

    const handlePhoneNumberChange = (phoneNumber: string, countryCode: string) => {
        setPhoneNumber(phoneNumber);
        setPhoneNumberCode(countryCode);
    };

    useEffect(() => {
        setState(prev => ({ ...prev, ['phoneNumber']: phoneNumber }));
        setDirty(true);
    }, [phoneNumber]);

    useEffect(() => {
        setState(prev => ({ ...prev, ['phoneCountryCode']: phoneNumberCode }));
        setDirty(true);
    }, [phoneNumberCode]);

    return (
        <ContentPage title={t('profile.title')}>
            <ProfileSectionWrapper
                handleSectionToggle={handleSectionToggle}
                section={ProfileSection.PRIMARY_INFORMATION}
                activeSection={activeSection}
                title={t('profile.profileSections.primaryInfo.title')}
                description={t('profile.profileSections.primaryInfo.description')}
            >
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="firstName"
                                value={userData ? `${userData.firstName} ${userData.lastName}` : ''}
                                label={t('profile.name')}
                                fullWidth
                                size="small"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="email"
                                value={state.email}
                                label={t('profile.email')}
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={onValueChanged('email')}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ProfileInputField
                                initial={initialState}
                                current={state}
                                property="addressStreet"
                                tKey="address"
                                onChange={onValueChanged}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ProfileInputField
                                initial={initialState}
                                current={state}
                                property="addressPostalTown"
                                tKey="city"
                                onChange={onValueChanged}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ProfileInputField
                                initial={initialState}
                                current={state}
                                property="addressPostalCode"
                                tKey="postalCode"
                                onChange={onValueChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ProfileInputField
                                initial={initialState}
                                current={state}
                                property="addressMunicipality"
                                tKey="placeOfResidence"
                                onChange={onValueChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneNumberInput
                                specialLabel={getMandatoryOrOptionalTranslation(t('common.phoneNumber'), false, t)}
                                name={'phoneNumber'}
                                number={phoneNumber ?? ''}
                                countryCode={phoneNumberCode ?? ''}
                                handleChange={handlePhoneNumberChange}
                                height={'40px'}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: 3 }}>
                        <Button variant="contained" color="primary" onClick={updateUserInfo} disabled={!dirty || loading} fullWidth>
                            {t('common.save')}
                            {loading && <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                        </Button>
                    </Box>
                </>
            </ProfileSectionWrapper>

            <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />

            {isExternalIdpToken && (
                <>
                    <Box mb={4}>
                        <ProfileSectionWrapper
                            handleSectionToggle={handleSectionToggle}
                            section={ProfileSection.SVK_INFO}
                            activeSection={activeSection}
                            title={t('profile.profileSections.svkInfo.title')}
                            description={''}
                        >
                            <SvkInfo />
                        </ProfileSectionWrapper>
                    </Box>
                    <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
                </>
            )}

            <Box mb={4}>
                <ProfileSectionWrapper
                    handleSectionToggle={handleSectionToggle}
                    section={ProfileSection.PASSWORD_CHANGE}
                    activeSection={activeSection}
                    title={t('profile.profileSections.changePassword.title')}
                    description={t('profile.profileSections.changePassword.description')}
                >
                    {!isExternalIdpToken ? (
                        <ChangePasswordForm />
                    ) : (
                        <Box alignItems={'center'} justifyContent={'center'}>
                            <Typography variant={'body1'}>
                                {t('profile.profileSections.changePassword.externalIdentity', { provider: capitalize(provider) })}
                            </Typography>
                        </Box>
                    )}
                </ProfileSectionWrapper>
            </Box>

            <Box mb={4}>
                <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />

                <ProfileSectionWrapper
                    handleSectionToggle={handleSectionToggle}
                    section={ProfileSection.USER_ROLE}
                    activeSection={activeSection}
                    title={t('profile.profileSections.userRole.title')}
                    description=""
                >
                    <UserRole />
                </ProfileSectionWrapper>
            </Box>

            <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />

            {isAdminUser === false && isEditorUser === false && (
                <Box mb={4}>
                    <ProfileSectionWrapper
                        handleSectionToggle={handleSectionToggle}
                        section={ProfileSection.USER_CONSENTS}
                        activeSection={activeSection}
                        title={t('profile.profileSections.userConsent.title')}
                        description=""
                    >
                        <UserConsents
                            actionButtonRenderer={({
                                latestUserConsents,
                                isDirty,
                                setIsDirty,
                                saveUserConsents,
                            }: ActionButtonRendererParams) => {
                                return (
                                    <Box sx={{ marginTop: 3 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                saveUserConsents(latestUserConsents);
                                                setIsDirty(false);
                                            }}
                                            disabled={!isDirty || loading}
                                            fullWidth
                                        >
                                            {t('common.save')}
                                            {loading && (
                                                <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />
                                            )}
                                        </Button>
                                    </Box>
                                );
                            }}
                        />
                    </ProfileSectionWrapper>
                </Box>
            )}
        </ContentPage>
    );
}

interface ProfileInputFieldProps {
    initial: UpdateUserRequest;
    current: UpdateUserRequest;
    property: keyof UpdateUserRequest;
    tKey?: string;
    onChange: (key: keyof UpdateUserRequest) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    adornment?: string;
}

/**
 * @return {JSX.Element}
 */
function ProfileInputField({ initial, current, property, tKey, onChange, adornment }: ProfileInputFieldProps) {
    const { t } = useTranslation();
    return (
        <TextField
            InputProps={{
                startAdornment: adornment !== undefined ? <InputAdornment position="start">{adornment}</InputAdornment> : undefined,
            }}
            id={property}
            value={current[property]}
            label={t(`profile.${tKey ?? property}`)}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ backgroundColor: initial[property] !== current[property] ? '#fff8d9' : 'unset' }}
            onChange={onChange(property)}
        />
    );
}
