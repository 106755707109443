import { isLinkTypeLoggedInOnly } from '@oma-kala-shared/core/logic';
import { CMSItem } from '@oma-kala-shared/core/model';
import { News } from '@oma-kala-shared/core/model/News';
import i18next from 'i18next';
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useSyncToken } from 'app/hooks';
import { selectCmsMessages } from 'app/state/cms/cmsSlice';
import { reSetOffset, selectHasMoreNews, selectNewsList, selectOffset, setOffset } from 'app/state/news/newsSlice';
import { fetchNews, fetchNextNews } from 'app/state/thunks/fecthNews';
import { selectIsLoggedIn } from 'app/state/user/userSlice';
import { NewsItemPreview } from 'app/components/display';

/**
 * @return {JSX.Element}
 */
export default function NewsSection() {
    const dispatch = useDispatch();
    const newsList: News[] = useSelector(selectNewsList);
    const offset: number = useSelector(selectOffset);
    const [syncToken, isLoading] = useSyncToken({ onSuccess: () => {}, onError: () => {} });

    useLayoutEffect(() => {
        dispatch(fetchNews(syncToken, 0, 18));
        dispatch(setOffset(18));
    }, []);

    useEffect(() => {
        dispatch(reSetOffset());
        dispatch(fetchNews(syncToken, 0, 18));
    }, [i18next.language]);

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 9,
        responsive: [
            {
                breakpoint: 3840,
                settings: {
                    slidesToShow: 9,
                    slidesToScroll: 9,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 3440,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 3040,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 2640,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 2240,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 1840,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
        ],
        beforeChange: () => dispatch(fetchNextNews(syncToken, offset, 18)),
    };

    return (
        <div style={{ margin: '30px 0', width: '100%' }}>
            {newsList.length > 0 && (
                <Slider {...sliderSettings}>
                    {newsList.map((news: News) => (
                        <div key={news.id}>
                            <NewsItemPreview item={news} isLoading={isLoading} />
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
}
