import React, { useImperativeHandle, useRef } from 'react';
import {
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    DeclarationRegulation,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import { GridValue, DYNAMIC_FIELD_TYPE, DynamicGridRef, FieldValueType, GridField } from 'app/components/common/dynamic-grid';

import { StatisticsDetailRef } from '../StatisticsDetail';
import TaxonomyCatchPropertyGrid from '../../TaxonomyCatchPropertyGrid';
import CatchDeclarationHelper, {
    DeclarationPropertyRequirements,
    GenerateFieldsFromRequirementsOptions,
} from 'app/components/declaration/helpers/CatchDeclarationHelper';

type SingleCatchStatisticsDetailProp = {
    catchProperties: DeclarationCatchProperty[];
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    onValueChange: (latestValue: GridValue) => void;
    fieldSizeHandler?: (field: DeclarationPropertyRequirements) => number;
    isInsideBulkCatch?: boolean;
    fieldGenerateOptions?: Omit<GenerateFieldsFromRequirementsOptions, 'requirements'>;
};

const SingleCatchStatisticsDetail = React.forwardRef<StatisticsDetailRef, SingleCatchStatisticsDetailProp>(
    (
        {
            catchProperties,
            taxonomyRequirement,
            onValueChange,
            isInsideBulkCatch = false,
            fieldGenerateOptions,
        }: SingleCatchStatisticsDetailProp,
        ref
    ) => {
        const statisticDetailRef = useRef<GridValue>({});
        const catchPropertyGridRef = useRef<DynamicGridRef>(null);

        const { t } = useTranslation();

        const requirementProperties = taxonomyRequirement.requiredTaxonomyProperties.filter(
            property => property.isBulkProperty === isInsideBulkCatch
        );

        const handleStatisticDetailChange = (newGridValue: GridValue) => {
            const updatedGridValue = CatchDeclarationHelper.handleAdiposeProperties(newGridValue);
            statisticDetailRef.current = { ...statisticDetailRef.current, ...updatedGridValue };

            validateStatisticDetail();
            onValueChange(statisticDetailRef.current);
        };

        useImperativeHandle(ref, () => ({
            validate: () => validateStatisticDetail(),
            getValue: () => statisticDetailRef.current,
            getChildCatches: () => [],
            getDefaultValues: () => {
                return preDefinedValues;
            },
        }));

        const validateStatisticDetail = () => {
            let isValid = true;

            if (!catchPropertyGridRef.current?.validate()) {
                isValid = false;
            }

            if (
                parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT] as string) >
                parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.COUNT] as string)
            ) {
                catchPropertyGridRef.current?.setError({
                    [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: t(
                        'catchDeclaration.newDeclaration.messages.releaseCountGreaterThanCountMessage'
                    ),
                });

                isValid = false;
            }

            if (
                statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT] &&
                parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT] as string) >
                    parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.COUNT] as string)
            ) {
                catchPropertyGridRef.current?.setError({
                    [DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT]: t(
                        'catchDeclaration.newDeclaration.messages.adiposeFinCutCountGreaterThanCountMessage'
                    ),
                });

                isValid = false;
            }

            if (
                (statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT] &&
                    statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT] &&
                    parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT] as string) >
                        parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT] as string)) ||
                parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT] as string) >
                    parseInt(statisticDetailRef.current![DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT] as string)
            ) {
                catchPropertyGridRef.current?.setError({
                    [DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT]: t(
                        'catchDeclaration.newDeclaration.messages.invalidAdiposeFinRealeaseCountMessage'
                    ),
                });

                isValid = false;
            }

            return isValid;
        };

        const getFieldTypeForProperty = (property: DeclarationPropertyRequirements): DYNAMIC_FIELD_TYPE => {
            // These property need to show as checkbox in single catch mode
            const specialStatisticsFields: string[] = [
                DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT,
                DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT,
            ];

            // const propertyName = getPropertyNameOnFrontend(property.name);
            const propertyName = property.name;

            if (specialStatisticsFields.includes(propertyName)) {
                return DYNAMIC_FIELD_TYPE.CHECKBOX;
            }

            return property.dataType as DYNAMIC_FIELD_TYPE;
        };

        const fieldFiltering = (property: DeclarationPropertyRequirements): boolean => {
            const ignoreProperties: string[] = [
                DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_COUNT,
                DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_RELEASE_COUNT,
                DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT,
            ];

            if (ignoreProperties.includes(property.name)) {
                return false;
            }

            return CatchDeclarationHelper.isStatisticProperty(property.name);
        };

        const visibilityHandler = (property: DeclarationPropertyRequirements): boolean => {
            const singleCatchHideFields: string[] = [
                DeclarationSpeciesRequirementPropertyName.COUNT,
                DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT,
            ];
            return singleCatchHideFields.includes(property.name) === false;
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            switch (property.name) {
                case DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT:
                    return t('catchDeclaration.singleCatchRelease');
                case DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT:
                    return t('catchDeclaration.singleCatchAdiposeFin');
                case DeclarationSpeciesRequirementPropertyName.WEIGHT:
                    return t('catchDeclaration.singleCatchWeight');
                default:
                    return t(`catchDeclaration.catchProperties.${property.name}`);
            }
        };

        const preDefinedValues: Record<string, FieldValueType> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 0,
            [DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT]: 0,
            [DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT]: 0,
            [DeclarationSpeciesRequirementPropertyName.COUNT]: 1,
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 0,
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 0,
        };

        const predefinedValidationRules: Record<string, string> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 'numeric|min:0',
            [DeclarationSpeciesRequirementPropertyName.COUNT]: 'numeric|min:1',
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 'required|greater_than:0',
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 'required|greater_than:0',
        };

        const weightProperty = requirementProperties.find(property => property.name === DeclarationSpeciesRequirementPropertyName.WEIGHT);

        // Set weight optional
        if (weightProperty && weightProperty.isOptional) {
            delete predefinedValidationRules[DeclarationSpeciesRequirementPropertyName.WEIGHT];
        }

        const fieldsPostHandler = (fields: Record<string, GridField>, fieldValues: Record<string, FieldValueType>) => {
            // Update the fields with the catch properties
            let { updatedFields, updatedValues } = CatchDeclarationHelper.updateFieldsWithCatchProperties(
                fields,
                fieldValues,
                catchProperties
            );

            updatedFields = handleAdiposeSpecies(fields);

            statisticDetailRef.current = {
                ...statisticDetailRef.current,
                ...updatedValues,
            };

            return updatedFields;
        };

        /**
         * Handle for adipose fin species like ATLACTIC SALMON
         *
         * @param {Record<string, GridField>} fields
         *
         * @return {Record<string, GridField>}
         */
        const handleAdiposeSpecies = (fields: Record<string, GridField>) => {
            if (fields[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT] === undefined) {
                return fields;
            }

            if (
                +fields[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT].value === 1 &&
                +fields[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT].value === 0
            ) {
                fields[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT].warningText = t(
                    `catchDeclaration.regulation.${DeclarationRegulation.ADIPOSE_FIN_SET_FREE}`,
                    { species: t(`taxonomyType.${taxonomyRequirement.idTaxonomy}`) }
                );
            }

            return fields;
        };

        const options: GenerateFieldsFromRequirementsOptions = {
            requirements: requirementProperties,
            fieldFiltering,
            predefinedDefaultValues: preDefinedValues,
            visibilityHandler: visibilityHandler,
            fieldTypeHandler: getFieldTypeForProperty,
            predefinedValidationRules,
            fieldLabelHandler,
            ...(fieldGenerateOptions ?? {}),
        };

        return (
            <TaxonomyCatchPropertyGrid
                ref={catchPropertyGridRef}
                generateFieldsFromRequirementsOptions={options}
                fieldsPostHandler={fieldsPostHandler}
                fieldsSortOrder={[
                    DeclarationSpeciesRequirementPropertyName.LENGTH,
                    DeclarationSpeciesRequirementPropertyName.WEIGHT,
                    DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT,
                    DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT,
                ]}
                onValueChange={handleStatisticDetailChange}
            />
        );
    }
);

SingleCatchStatisticsDetail.displayName = 'SingleCatchStatisticsDetail';

export default SingleCatchStatisticsDetail;
