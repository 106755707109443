import { Role } from '@oma-kala-shared/core/model';
import { useSyncToken } from 'app/hooks';
import { fetchRoles } from 'app/state/thunks/fetchRoles';
import React, { useContext, useState, useEffect, createContext } from 'react';
import { useDispatch } from 'react-redux';

export type FilterCriteria = {
    field: 'email' | 'role';
    value: string;
};

export interface UserManagementViewContextProps {
    roles: Role[];
    filterCriteria: FilterCriteria | null;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria | null>>;
    showBackdrop: boolean;
    setShowBackdrop: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserManagementViewContext = createContext<UserManagementViewContextProps | undefined>(undefined);

export const useUserManagementViewContext = () => {
    const context = useContext(UserManagementViewContext);
    if (!context) {
        throw new Error('UserManagementViewContext must be used within a useUserManagementViewContext');
    }
    return context;
};

interface UserManagementViewContextProviderProps {
    children: React.ReactNode;
}

export const ManagementViewContextProvider: React.FC<UserManagementViewContextProviderProps> = ({ children }) => {
    const dispatch = useDispatch();

    const [roles, setRoles] = useState<Role[]>([]);
    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria | null>(null);
    const [showBackdrop, setShowBackdrop] = useState(false);

    const [getRolesToken] = useSyncToken({
        onSuccess: (roles: Role[]) => {
            setRoles(roles);
        },
        onError: () => {},
    });

    useEffect(() => {
        dispatch(fetchRoles(getRolesToken));
    }, [dispatch]);

    return (
        <UserManagementViewContext.Provider
            value={{
                roles,
                filterCriteria,
                setFilterCriteria,
                showBackdrop,
                setShowBackdrop,
            }}
        >
            {children}
        </UserManagementViewContext.Provider>
    );
};
