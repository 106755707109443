import { OmaKalaLanguageCode } from 'app/model';
// Store image button
import appStoreButtonImageEn from 'resources/images/app-store-button-en.svg';
import appStoreButtonImageFi from 'resources/images/app-store-button-fi.svg';
import appStoreButtonImageSv from 'resources/images/app-store-button-sv.svg';

import playStoreButtonImageEn from 'resources/images/play-store-button-en.png';
import playStoreButtonImageFi from 'resources/images/play-store-button-fi.png';
import playStoreButtonImageSv from 'resources/images/play-store-button-sv.png';

// Qr Code
import qrCodeAppStore from 'resources/images/app-store-qr-code.svg';
import qrCodePlayStore from 'resources/images/play-store-qr-code.svg';

export enum StoreVariantEnum {
    APP_STORE = 'app',
    PLAY_STORE = 'play',
}

export const getStoreQrCodeImage = (variant: StoreVariantEnum) => {
    return variant === StoreVariantEnum.PLAY_STORE ? qrCodePlayStore : qrCodeAppStore;
};

export const getStoreButtonImage = (lang: string, variant: StoreVariantEnum) => {
    switch (variant) {
        case StoreVariantEnum.PLAY_STORE:
            switch (lang) {
                case OmaKalaLanguageCode.EN:
                    return playStoreButtonImageEn;
                case OmaKalaLanguageCode.SV:
                    return playStoreButtonImageSv;
                // TODO: for now return FI, later will replace with Sami
                case OmaKalaLanguageCode.SE:
                case OmaKalaLanguageCode.FI:
                default:
                    return playStoreButtonImageFi;
            }
        case StoreVariantEnum.APP_STORE:
            switch (lang) {
                case OmaKalaLanguageCode.EN:
                    return appStoreButtonImageEn;
                case OmaKalaLanguageCode.SV:
                    return appStoreButtonImageSv;
                // TODO: for now return FI, later will replace with Sami
                case OmaKalaLanguageCode.SE:
                case OmaKalaLanguageCode.FI:
                default:
                    return appStoreButtonImageFi;
            }
        default:
            return playStoreButtonImageFi;
    }
};
