import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box, Button, CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';

import {
    GeoJsonPoint,
    SpatialRequirementResponse,
    SpatialRequirementTypeEnum,
    DeclarationDetails,
    DeclarationSpeciesRequirementDetails,
    DeclarationCatchDetails,
    DeclarationSpeciesRequirementPropertyName,
    KnownTaxonomyType,
    CatchGeneralizedLocation as CatchGeneralizedLocationType,
    DeclarationSpeciesPropertyRequirementDetails,
} from '@oma-kala-shared/core/model';
import { validateDeclarationCatch } from '@oma-kala-shared/core/logic/DeclarationService';
import { MessageType } from 'app/model';
import { Message } from 'app/model/Message';

import { DynamicGridRef, SelectFieldItem } from 'app/components/common/dynamic-grid';
import FishingTripDetail, { FishingTripDetailsRef, SpeciesFieldName } from './details/fishing_trip/FishingTripDetail';
import StatisticsDetail, { StatisticsDetailRef } from './details/statistic/StatisticsDetail';
import CatchGeneralizedLocation from 'app/components/declaration/display/CatchGeneralizedLocation';
import { AlertDialogComponent } from 'app/components/common/AlertDialogComponent';
import CatchDeclarationHelper from 'app/components/declaration/helpers/CatchDeclarationHelper';

export interface DeclarationCatchDetailProps {
    declaration: DeclarationDetails;
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    onCancel: () => void;
    onSave: (catchDetail: DeclarationCatchDetails) => void;
    allowCancel?: boolean;
    catchDetail?: DeclarationCatchDetails | null;
    onValueChange: (catchDetails: DeclarationCatchDetails | null) => void;
}

const populateAdiposeFinForTaxonomyRequirements = (taxonomyRequirements: DeclarationSpeciesRequirementDetails) => {
    const clonedTaxonomyRequirements = { ...taxonomyRequirements };

    const adiposeFinCutCountProperty = taxonomyRequirements.requiredTaxonomyProperties.find(
        property => property.name === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_COUNT
    );

    const adiposeFinCountProperty = taxonomyRequirements.requiredTaxonomyProperties.find(
        property => property.name === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT
    );

    // When ADIPOSE_FIN_CUT_COUNT is present, add ADIPOSE_FIN_COUNT property
    if (adiposeFinCutCountProperty && !adiposeFinCountProperty) {
        const adiposeFinCountProperty: DeclarationSpeciesPropertyRequirementDetails = {
            id: -1,
            name: DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT,
            dataType: 'integer',
            unit: null,
            idTaxonomyRequiredTaxonomy: taxonomyRequirements.idTaxonomy, // MX-code
            idDeclarationRequirementRequiredTaxonomy: -1,
            isBulkProperty: false,
            isOptional: false,
        };

        const adiposeFinReleaseCountProperty: DeclarationSpeciesPropertyRequirementDetails = {
            id: -2,
            name: DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT,
            dataType: 'integer',
            unit: null,
            idTaxonomyRequiredTaxonomy: taxonomyRequirements.idTaxonomy, // MX-code
            idDeclarationRequirementRequiredTaxonomy: -1,
            isBulkProperty: false,
            isOptional: false,
        };

        clonedTaxonomyRequirements.requiredTaxonomyProperties = [
            ...taxonomyRequirements.requiredTaxonomyProperties,
            adiposeFinCountProperty,
            adiposeFinReleaseCountProperty,
        ];
    }

    return clonedTaxonomyRequirements;
};

export const DeclarationCatchDetail = React.forwardRef<DynamicGridRef, DeclarationCatchDetailProps>(props => {
    const { t } = useTranslation();
    const { declaration, declarationTaxonomyRequirement, catchDetail, onSave, onCancel, allowCancel, onValueChange } = props;
    const [alert, setAlert] = useState<Message | null>(null);

    let currentTaxonomyRequirementState = declarationTaxonomyRequirement[0];

    if (catchDetail) {
        currentTaxonomyRequirementState = declarationTaxonomyRequirement.find(
            requirement => requirement.idTaxonomy === catchDetail.idTaxonomyRequiredTaxonomy
        ) as DeclarationSpeciesRequirementDetails;

        currentTaxonomyRequirementState = populateAdiposeFinForTaxonomyRequirements(currentTaxonomyRequirementState);
    }

    const [currentTaxonomyRequirement, setCurrentTaxonomyRequirement] =
        useState<DeclarationSpeciesRequirementDetails>(currentTaxonomyRequirementState);

    let catchProperties = catchDetail?.catchProperties ?? [];

    let childCatches = catchDetail?.childCatches ?? [];

    if (catchProperties.length > 0) {
        if (childCatches.length === 0) {
            catchProperties = CatchDeclarationHelper.handleAiposeFinForCatchDetails(catchDetail!).catchProperties;
        } else {
            const newChildCatches = childCatches.map(childCatch => {
                return CatchDeclarationHelper.handleAiposeFinForChildCatchProperties(childCatch);
            });

            childCatches = newChildCatches;
        }
    }

    const fishingTripDetailFormRef = useRef<FishingTripDetailsRef>(null);
    const statisticsDetailFormRef = useRef<StatisticsDetailRef>(null);
    const [loading] = useState<boolean>(false);
    const [catchGeneralizedLocation, setCatchGeneralizedLocation] = useState<CatchGeneralizedLocationType | null>(
        catchDetail?.generalizedLocation ?? null
    );

    const [spatialRequirementType, setSpatialRequirementType] = useState<SpatialRequirementTypeEnum | null>(null);

    const [catchGeometry, setCatchGeometry] = useState<GeoJsonPoint | null>(catchDetail?.geometry ?? null);

    const taxonomies: SelectFieldItem[] = [];
    declarationTaxonomyRequirement.forEach(taxonomy => {
        taxonomies.push({
            label: t(`taxonomyType.${taxonomy.idTaxonomy}`),
            value: taxonomy.idTaxonomy,
        });
    });

    const handleStatisticDetailChange = () => {
        onValueChange(generateDeclarationCatchDetails(false));
    };

    const onTaxonomyChanged = (taxonomyRequirements: DeclarationSpeciesRequirementDetails) => {
        setCurrentTaxonomyRequirement(populateAdiposeFinForTaxonomyRequirements(taxonomyRequirements));
        onValueChange(generateDeclarationCatchDetails(false));
    };

    /**
     * Validates the form data for a catch declaration.
     *
     * @param {DeclarationCatchDetails | null} catchDetails - The details of the catch to be validated.
     * @return {boolean} - Returns true if the form data is valid, otherwise false.
     *
     * The function performs validation by:
     * - Checking the validity of the fishing trip details form.
     * - Checking the validity of the statistics detail form.
     * - Ensuring that the catch has a generalized location with non-empty labels.
     * - Validating the catch details against the declaration and taxonomy requirements.
     */
    const isValidCatchDetail = (catchDetails: DeclarationCatchDetails | null) => {
        return (
            fishingTripDetailFormRef.current?.validate() &&
            statisticsDetailFormRef.current?.validate() &&
            !!catchGeneralizedLocation &&
            !isEmpty(catchGeneralizedLocation.featureLabel) &&
            !isEmpty(catchGeneralizedLocation.featureLabelSecondary) &&
            spatialRequirementType !== SpatialRequirementTypeEnum.NOT_REQUIRED &&
            (!catchDetails ||
                validateDeclarationCatch(declaration, catchDetails, currentTaxonomyRequirementState.requiredTaxonomyProperties).length ===
                    0)
        );
    };

    /**
     * Generates the details for a catch declaration.
     *
     * @param {boolean} [needValidate] - Flag indicating whether validation is required.
     * @returns {DeclarationCatchDetails | null} - Returns the generated catch details or null if validation fails.
     *
     * The function creates a new catch details object or clones the existing one, populates it with values from
     * the fishing trip and statistics detail forms, and updates its properties based on the grid values.
     * It also updates the catch time, taxonomy, geometry, generalized location, and child catches.
     * If validation is required and the catch details are invalid, the function returns null.
     */

    const generateDeclarationCatchDetails = (needValidate?: boolean): DeclarationCatchDetails | null => {
        const catchInfo = catchDetail
            ? { ...catchDetail }
            : {
                  catchTime: '',
                  idTaxonomyRequiredTaxonomy: currentTaxonomyRequirement.idTaxonomy,
                  idDeclarationRequirementRequiredTaxonomy: currentTaxonomyRequirement.idDeclarationRequirement,
                  externalReference: null,
                  catchProperties: [],
                  geometry: null,
                  generalizedLocation: null,
                  tempId: `catchDetails_${new Date().getTime().toString()}`,
                  childCatches: [],
              };

        if (needValidate && !isValidCatchDetail(catchInfo)) {
            return null;
        }

        const gridValue = {
            ...fishingTripDetailFormRef.current?.getValue(),
            ...statisticsDetailFormRef.current?.getValue(),
        };

        catchInfo.catchProperties = CatchDeclarationHelper.generateCatchPropertiesFromGridValue(
            currentTaxonomyRequirement.requiredTaxonomyProperties.filter(property => !property.isBulkProperty),
            catchProperties,
            gridValue
        );

        if (gridValue[DeclarationSpeciesRequirementPropertyName.CATCH_DATE]) {
            catchInfo.catchTime = moment(gridValue[DeclarationSpeciesRequirementPropertyName.CATCH_DATE] as string).toISOString();
        }

        if (gridValue[SpeciesFieldName]) {
            catchInfo.idTaxonomyRequiredTaxonomy = gridValue[SpeciesFieldName] as KnownTaxonomyType;
        }

        catchInfo.geometry = catchGeometry;
        catchInfo.generalizedLocation = catchGeneralizedLocation;
        catchInfo.childCatches = statisticsDetailFormRef.current?.getChildCatches() ?? [];
        return catchInfo;
    };

    /**
     * Called when the catch type changes to remove statistic properties from the catch properties list
     */
    const handleCatchTypeChange = () => {
        if (!catchDetail || !catchDetail.catchProperties) {
            return;
        }

        const newCatchInfo = { ...catchDetail };

        const defaultValue = statisticsDetailFormRef.current?.getDefaultValues();
        if (defaultValue) {
            newCatchInfo.catchProperties = catchDetail.catchProperties.map(property => {
                const defaultValueProperty = defaultValue[property.propertyName as string];
                return { ...property, value: defaultValueProperty ? defaultValueProperty.toString() : property.value };
            });
        }

        onValueChange(newCatchInfo);
    };

    const handleFormSave = () => {
        const catchInfo = generateDeclarationCatchDetails();

        if (catchInfo === null) {
            return;
        }

        onSave(catchInfo);
    };

    const handleFormCancel = () => {
        onCancel();
    };

    /**
     * For now catch form only render when we have fisher requirements
     */
    if (declarationTaxonomyRequirement.length === 0) {
        return <></>;
    }

    const onSpatialRequirementsUpdated = (response: SpatialRequirementResponse | null) => {
        if (!response) return;
        setSpatialRequirementType(response.spatialRequirementType);
        if (response.spatialRequirementType === SpatialRequirementTypeEnum.OPTIONAL) {
            setAlert({
                text: t('catchDeclaration.optionalDeclarationNotis'),
                type: MessageType.INFO,
            });
        } else if (response.spatialRequirementType === SpatialRequirementTypeEnum.OPTIONAL_BY_AREA) {
            setAlert({
                text: t('catchDeclaration.optionalByAreaDeclarationNotis'),
                type: MessageType.INFO,
            });
        } else if (response.spatialRequirementType === SpatialRequirementTypeEnum.NOT_REQUIRED) {
            setAlert({
                text: t('catchDeclaration.notNeededEx.TAXONOMY_NOT_REQUIRED').replace(
                    '{}',
                    t(`taxonomyType.${catchDetail?.idTaxonomyRequiredTaxonomy}`)
                ),
                type: MessageType.INFO,
            });
        }
    };
    const getDetailElement = () => {
        return (
            <>
                <FishingTripDetail
                    ref={fishingTripDetailFormRef}
                    taxonomyRequirement={currentTaxonomyRequirement}
                    catchProperties={catchProperties}
                    declarationTaxonomyRequirement={declarationTaxonomyRequirement}
                    taxonomies={taxonomies}
                    onTaxonomyChanged={onTaxonomyChanged}
                />
                <StatisticsDetail
                    ref={statisticsDetailFormRef}
                    taxonomyRequirement={currentTaxonomyRequirement}
                    catchProperties={catchProperties}
                    childCatches={childCatches}
                    declarationTaxonomyRequirement={declarationTaxonomyRequirement}
                    onValueChange={handleStatisticDetailChange}
                    onCatchTypeChange={handleCatchTypeChange}
                />

                <CatchGeneralizedLocation
                    generalizedLocation={catchDetail?.generalizedLocation ?? null}
                    onGeometrySet={setCatchGeometry}
                    onGeneralizedLocationSet={setCatchGeneralizedLocation}
                    onSpatialRequirementsUpdated={onSpatialRequirementsUpdated}
                    showCircleOnMap={catchDetail?.id === undefined}
                    showExactLocation={catchDetail?.id === undefined}
                    catchDetails={catchDetail}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 8, marginBottom: 4 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFormSave}
                        disabled={!isValidCatchDetail(null)}
                        sx={{ minWidth: 200, marginRight: 2 }}
                    >
                        {t('common.save')}
                        {loading && <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                    </Button>

                    {allowCancel === true && (
                        <Button
                            variant="outlined"
                            onClick={handleFormCancel}
                            sx={{
                                minWidth: 200,
                            }}
                        >
                            {t('common.cancel')}
                        </Button>
                    )}
                    {alert && <AlertDialogComponent props={alert} open={alert !== null} handleClose={() => setAlert(null)} />}
                </Box>
            </>
        );
    };
    return <>{getDetailElement()}</>;
});

DeclarationCatchDetail.displayName = 'CreateDeclarationCatchForm';
