import { useStoreButtonImage } from 'app/hooks/useStoreButtonImage';
import { useStoreLinks } from 'app/hooks/useStoreLink';
import { StoreVariantEnum } from 'app/logic/ImageService';
import React from 'react';
import { useTranslation } from 'react-i18next';

type AppStoreLinkProps = {
    style?: React.CSSProperties;
    marketplace: StoreVariantEnum;
};

const StoreImageButton: React.FC<AppStoreLinkProps> = ({ style = {}, marketplace }) => {
    const [appStoreButtonImage, playStoreButtonImage] = useStoreButtonImage();
    const [appStoreLink, playStoreLink] = useStoreLinks();
    const { t } = useTranslation();

    const storeLinks = marketplace === StoreVariantEnum.PLAY_STORE ? playStoreLink : appStoreLink;
    const imgSrc = marketplace === StoreVariantEnum.PLAY_STORE ? playStoreButtonImage : appStoreButtonImage;
    const altText = t(`common.mobile.${marketplace === StoreVariantEnum.PLAY_STORE ? 'playStore' : 'appStore'}.linkAltText`);

    return (
        <a href={storeLinks}>
            <img
                style={{
                    height: '60px',
                    ...style,
                }}
                src={imgSrc}
                alt={altText}
            />
        </a>
    );
};

export default StoreImageButton;
