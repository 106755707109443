import React from 'react';
import { Chip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { Role } from '@oma-kala-shared/core/model';
import { t } from 'i18next';

interface RoleChipProps {
    role: Role;
    isAssigned: boolean;
    isUnassignable: boolean;
    onClick: () => void;
}

const RoleChip: React.FC<RoleChipProps> = ({ role, isAssigned, isUnassignable, onClick }) => {
    return (
        <Chip
            key={role.id}
            icon={isUnassignable ? <LockIcon style={{ color: isAssigned ? 'white' : 'black' }} /> : undefined}
            label={t(`common.roles.${role.code}`) as string}
            onClick={!isUnassignable ? onClick : undefined}
            variant="outlined"
            sx={{
                backgroundColor: isAssigned ? 'primary.main' : 'default',
                color: isAssigned ? 'white' : isUnassignable ? 'black' : 'default',
                cursor: isUnassignable ? 'not-allowed' : 'pointer',
                opacity: isUnassignable && !isAssigned ? 0.6 : 1,
                borderWidth: 2,
            }}
        />
    );
};

export default RoleChip;
