import { useState, useEffect } from 'react';

const useDirtyCheck = <T>(initialValue: T) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setIsDirty(JSON.stringify(value) !== JSON.stringify(initialValue));
    }, [value, initialValue]);

    const resetDirty = () => setIsDirty(false);

    return { value, setValue, isDirty, resetDirty, setDirty: setIsDirty };
};

export default useDirtyCheck;
