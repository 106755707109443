import { CardMedia } from '@mui/material';
import { getStoreQrCodeImage, StoreVariantEnum } from 'app/logic/ImageService';
import React from 'react';
import { useTranslation } from 'react-i18next';

type StoreQrCodeProps = {
    style?: React.CSSProperties;
    marketplace: StoreVariantEnum;
};

const StoreQrCode: React.FC<StoreQrCodeProps> = ({ style = {}, marketplace }) => {
    const { t } = useTranslation();

    const imgSrc = getStoreQrCodeImage(marketplace);
    const altText = t(`common.mobile.${marketplace === StoreVariantEnum.PLAY_STORE ? 'playStore' : 'appStore'}.linkAltText`);

    return <CardMedia sx={style} component="img" image={imgSrc} alt={altText} />;
};

export default StoreQrCode;
