import { UserData } from '@oma-kala-shared/core/model';

import { UserRole } from 'app/model/UserRole';

import { clearSetting, getSetting, storeSetting, userRelatedSettings } from './StorageService';
import moment, { Moment } from 'moment';

/**
 * Add all setting keys to this type to assist with linting and intellisense.
 */
export type UserSettingKey = 'applicationLanguage' | 'tokenData' | 'userData' | 'userRoles' | 'userRolesLastFetched' | 'latestLoggedInUser';

/**
 * Stores the user settings
 * @param {UserData} data The data to store
 */
export function storeUserData(data: UserData) {
    storeSetting('userData', JSON.stringify(data));
}

/**
 * Stores the user roles
 * @param {UserRole[]} data The data to store
 */
export function storeUserRoles(data: UserRole[]) {
    storeSetting('userRoles', JSON.stringify(data));
    storeUserRolesLastFetched(moment());
}

/**
 * Stores the time when the user's roles were last fetched
 * @param {Moment} fetchTS The time when the user's roles were last fetched
 */
export function storeUserRolesLastFetched(fetchTS: Moment) {
    storeSetting('userRolesLastFetched', fetchTS.toISOString());
}

/**
 * Clears the user roles from local storage
 */
export function clearUserRoles() {
    clearSetting('userRoles');
    clearSetting('userRolesLastFetched');
}
/**
 * Get last fetch time of user's role
 *
 * @return {Moment | null}
 */
export function getUserRolesLastFetched() {
    const data: string | null = getSetting('userRolesLastFetched');
    return data === null ? null : moment(data);
}

/**
 * Clears the user data from local storage
 */
export function clearUserData() {
    clearSetting('userData');
}

/**
 * Fetches user data from local storage
 * @return {UserData | null} The user data if it exists, else null
 */
export function getUserData(): UserData | null {
    const data: string | null = getSetting('userData');
    return data === null ? null : JSON.parse(data);
}

/**
 * Fetches user roles from local storage
 * @return {UserRoles[]} The user data if it exists, else null
 */
export function getUserRoles(): UserRole[] | null {
    const data: string | null = getSetting('userRoles');
    return data === null ? null : JSON.parse(data);
}

/**
 * Stores the ID of the latest logged in user.
 * @param {string} userId ID of the user
 */
export function storeLatestLoggedInUser(userId: string) {
    storeSetting('latestLoggedInUser', userId);
}

/**
 * Gets the ID of the latest logged in user.
 * @return {string | null}
 */
export function getLatestLoggedInUser(): string | null {
    return getSetting('latestLoggedInUser');
}

/**
 * Removes all settings related to the logged in user from local storage
 */
export function clearAllUserRelatedSettings() {
    for (const setting of userRelatedSettings) {
        clearSetting(setting);
    }
}

/**
 * If the latest logged in user is another user than the current user, removes all settings related to the previously logged in user.
 * @param {string} currentUserId ID of the current user (whose settings should _not_ be cleared)
 */
export function clearUserRelatedSettingsForOtherUsers(currentUserId: string) {
    const latestLoggedInUser: string | null = getLatestLoggedInUser();
    if (currentUserId !== latestLoggedInUser) {
        console.log(`${currentUserId} !== ${latestLoggedInUser}; clearing user settings`);
        clearAllUserRelatedSettings();
    }
}
