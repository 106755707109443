import { OmaKalaUserRole } from 'app/model';

import { DrawerFolderName } from './../components/layout/drawer/types';

export enum RouteName {
    HOME = 'HOME',
    FEEDBACK = 'FEEDBACK',
    PROFILE = 'PROFILE',
    NEWS = 'NEWS',
    NEWS_ITEM = 'NEWS_ITEM',
    CMS_ITEM = 'CMS_ITEM',
    TECHNICAL_REQUIREMENTS = 'TECHNICAL_REQUIREMENTS',
    TERMS_AND_CONDITION = 'TERMS_AND_CONDITION',
    ABOUT = 'ABOUT',
    NOTIFICATIONS = 'NOTIFICATIONS',
    SEND_NOTIFICATION = 'SEND_NOTIFICATION',
    LOGIN = 'LOGIN',
    RESET_PASSWORD = 'RESET_PASSWORD',
    REGISTRATION = 'REGISTRATION',
    GEAR = 'GEAR',
    GEAR_ITEM = 'GEAR_ITEM',
    CREATE_CATCH_DECLARATION = 'CREATE_CATCH_DECLARATION',
    CATCH_DECLARATION_GROUP = 'CATCH_DECLARATION_GROUP',
    CATCH_DECLARATION_PERSONAL = 'CATCH_DECLARATION_PERSONAL',
    CATCH_DECLARATION = 'CATCH_DECLARATION',
    SUPPORT = 'SUPPORT',
    PRIVACY_POLICY = 'RIVACY_POLICY',
    USER_MANAGEMENT = 'USER_MANAGEMENT',
}

export enum RoutePath {
    CREATE_CATCH_DECLARATION = '/catchDeclaration/create/:type',
    CATCH_DECLARATION_GROUP = '/catchDeclaration?type=group',
    CATCH_DECLARATION_PERSONAL = '/catchDeclaration?type=personal',
    PROFILE = '/profile',
    NEWS = '/news',
    NEWS_ITEM = '/news/:newsId',
    CMS_ITEM = '/pages/:cmsTagName',
    HOME = '/home',
    LOGIN = '/auth',
    FEEDBACK = '/feedback',
    TECHNICAL_REQUIREMENTS = '/technical-requirements',
    TERMS_AND_CONDITION = '/terms-and-conditions',
    ABOUT = '/about',
    NOTIFICATIONS = '/admin/notification/browse',
    SEND_NOTIFICATION = '/admin/notification/send',
    RESET_PASSWORD = '/resetpassword',
    REGISTRATION = '/auth/registration',
    GEAR = '/gear',
    GEAR_ITEM = '/gear/:gearId',
    PRIVACY_POLICY = '/privacy-policy',
    SUPPORT = '/support',
    CATCH_DECLARATION = '/catchDeclaration',
    USER_MANAGEMENT = '/admin/user-management',
}

export interface RouteProperties {
    path: string;
    paths?: string[];
    name: RouteName;
    component: JSX.Element;
    allowedRoles: OmaKalaUserRole[] | null;
    hideForLoggedIn?: boolean;
    dangerousHtmlAllowed?: boolean;
    icon?: JSX.Element;
}

export interface DrawerMenuItem {
    path?: string;
    name: string;
    icon?: JSX.Element;
    subMenu?: DrawerMenuItem[];
    cmsLink?: DrawerFolderName;
    dangerousHtmlAllowed?: boolean;
}
