import WebService from '@oma-kala-shared/core/logic/WebService';
import {
    ConsentIdentifier,
    ContentResponse,
    CreateDeclarationDetails,
    DeclarationDetails,
    DeclarationNotRequiredException,
    SimpleResponse,
    UpdateUserRoleRequest,
} from '@oma-kala-shared/core/model';
import i18n from 'i18next';
import { OmaKalaLanguageCode, RequestStatus } from 'app/model';
import { setErrorMessage } from 'app/state/message/messageSlice';
import { setSuccessMessage } from '../message/messageSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';
import { selectUserData } from '../user/userSlice';

/**
 * Create or update the specified declaration
 * @param {string} syncToken
 * @param {string} userId
 * @param {UpdateUserRoleRequest} request
 * @return {Promise<ContentResponse<DeclarationDetails>>}
 */
export const updateRoleForUser =
    (syncToken: string, userId: string, request: UpdateUserRoleRequest): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.debug('Update user roles', request);

        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const response = await service.updateUserRole(userId, request);

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                    },
                })
            );
        } else {
            console.debug('Update roles for user failed with message:' + response.message);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: response.message as string,
                    },
                })
            );
        }
    };
