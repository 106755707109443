import React, { useImperativeHandle, useRef } from 'react';

import { GridValue, DynamicGridRef, FieldValueType, GridField } from 'app/components/common/dynamic-grid';
import DynamicGrid from 'app/components/common/dynamic-grid/DynamicGrid';
import CatchDeclarationHelper, {
    GenerateFieldsFromRequirementsOptions,
} from '../../../helpers/CatchDeclarationHelper';

type TaxonomyCatchPropertyGridProp = {
    generateFieldsFromRequirementsOptions: GenerateFieldsFromRequirementsOptions;
    fieldsPostHandler?: (fields: Record<string, GridField>, fieldValues: Record<string, FieldValueType>) => Record<string, GridField>;
    fieldsSortOrder?: string[];
    onValueChange: (latestValue: GridValue) => void;
};

const TaxonomyCatchPropertyGrid = React.forwardRef<DynamicGridRef, TaxonomyCatchPropertyGridProp>(
    (
        {
            generateFieldsFromRequirementsOptions,
            fieldsSortOrder: fieldsOrder,
            fieldsPostHandler: fieldsPostHandler,
            onValueChange,
        }: TaxonomyCatchPropertyGridProp,
        ref
    ) => {
        const gridValue = useRef<GridValue | null>(null);
        const gridComponent = useRef<DynamicGridRef | null>(null);

        useImperativeHandle(ref, () => ({
            validate: () => {
                return gridComponent.current?.validate() === true;
            },
            getValue: () => {
                return gridValue.current;
            },
            setError: (error: Record<string, string>) => {
                gridComponent.current?.setError(error);
            },
        }));

        // Need to create fields from requiredFields
        let [fields, fieldValues] = CatchDeclarationHelper.generateFieldsFromRequirements(generateFieldsFromRequirementsOptions);

        /**
         * Update the ref value
         */
        gridValue.current = {
            ...(gridValue.current ?? {}),
            ...fieldValues,
        };

        if (fieldsPostHandler) {
            fields = fieldsPostHandler(fields, fieldValues!);
        }

        const sortFunction = (a: GridField, b: GridField, fieldsOrder: string[]) => {
            const indexA = fieldsOrder.indexOf(a.name);
            const indexB = fieldsOrder.indexOf(b.name);
            // If either value is not in the sortOrder array, it should come later if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
        };

        const sortedFields = Object.values(fields).sort((a, b) => sortFunction(a, b, fieldsOrder ?? []));

        return <DynamicGrid ref={gridComponent} gridFields={[sortedFields]} onValueChange={onValueChange} />;
    }
);

TaxonomyCatchPropertyGrid.displayName = 'TaxonomyCatchPropertyGrid';

export default TaxonomyCatchPropertyGrid;
