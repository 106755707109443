import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Pagination,
    Select,
    MenuItem,
} from '@mui/material';

interface GenericTableProps<T> {
    title?: string; // Optional title for the table
    headers: string[]; // Array of header labels
    data: T[]; // Array of data to display
    renderRow: (row: T, index: number) => React.ReactNode; // Render prop for custom row rendering
    rowKey: (row: T) => string | number; // Function to generate a unique key for each row
    pageSizeOptions?: number[]; // Options for page size selector
    totalItems: number; // Total number of items for pagination
    currentPage: number; // Current page number
    pageSize: number; // Current page size
    totalPages: number; // Total number of pages (optional, can be calculated from totalItems and pageSize)
    onPageChange?: (page: number) => void; // Callback for page change
    onPageSizeChange?: (size: number) => void; // Callback for page size change
}

const GenericTable = <T,>({
    title,
    headers,
    data,
    renderRow,
    rowKey,
    pageSizeOptions = [5, 10, 20],
    totalPages,
    pageSize = 10,
    currentPage = 0,
    totalItems,
    onPageChange,
    onPageSizeChange,
}: GenericTableProps<T>) => {
    const startIndex = (currentPage) * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems);

    const renderPageControl = () => {
        if (onPageChange || onPageSizeChange) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {/* Pagination */}
                    {onPageChange && (
                        <Pagination
                            page={currentPage + 1}
                            count={totalPages}
                            color="primary"
                            onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                                onPageChange(page);
                            }}
                        />
                    )}

                    {/* Page Size Selector */}
                    {onPageSizeChange && (
                        <Select value={pageSize} onChange={e => onPageSizeChange(Number(e.target.value))} sx={{ width: 100 }}>
                            {pageSizeOptions.map(size => (
                                <MenuItem key={size} value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </Box>
            );
        }
        return null;
    };

    return (
        <Box>
            {/* Table Title */}
            {title && (
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {title}
                </Typography>
            )}

            {/* Table */}
            <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
                <Table>
                    {/* Table Header */}
                    <TableHead sx={{ backgroundColor: '#f1f3f9' }}>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={index} sx={{ fontWeight: 'bold' }}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                        {data.map((row, index) => (
                            <React.Fragment key={rowKey(row)}>{renderRow(row, index)}</React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {renderPageControl()}
        </Box>
    );
};

export default GenericTable;
