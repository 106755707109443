import React, { useRef, useState } from 'react';
import { Grid, TextField, Button, InputAdornment, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { Email } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FilterCriteria } from 'app/pages/user-management/views/management/ManagementViewContext';
import { Role } from '@oma-kala-shared/core/model';
import { validateEmail } from '@oma-kala-shared/core/logic';

type UserSearchFilterProps = {
    roles: Role[];
    onSearch: (criteria: FilterCriteria) => void;
};

const UserSearchFilter: React.FC<UserSearchFilterProps> = ({ onSearch, roles }) => {
    const emailRef = useRef<HTMLInputElement>(null);
    const roleRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string | null>(null);
    const [searchBy, setSearchBy] = useState<'email' | 'role'>('email');
    const [selectedRole, setSelectedRole] = useState<string>(roles.at(0)?.id ?? ''); // Add state for selected role
    const { t } = useTranslation();

    // Extracted translation variables
    const filterByLabel = t('admin.userManagement.searchPanel.filterBy');
    const emailLabel = t('common.email', 'Email');
    const roleLabel = t('common.role', 'Role');
    const searchButtonLabel = t('common.search');
    const emailValidationError = t('common.validationErrors.email');

    const handleSearch = () => {
        const email = emailRef.current?.value.trim() || '';
        const role = roleRef.current?.value.trim() || '';

        if (searchBy === 'email' && !validateEmail(email)) {
            setError(emailValidationError);
            return;
        }

        setError(null);
        const criteria: FilterCriteria = email ? { field: 'email', value: email } : { field: 'role', value: role };
        onSearch(criteria);
    };

    const handleSearchByChange = (event: SelectChangeEvent<'email' | 'role'>) => {
        setSearchBy(event.target.value as 'email' | 'role');
        setError(null);
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRole(event.target.value); // Update selected role state
        setError(null);
    };

    const renderSearchInput = () => {
        switch (searchBy) {
            case 'email':
                return (
                    <TextField
                        type="email"
                        placeholder="john.doe@example.com"
                        inputRef={emailRef}
                        error={!!error}
                        helperText={error}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                    />
                );
            case 'role':
                return (
                    <TextField
                        select
                        inputRef={roleRef}
                        value={selectedRole} // Bind value to selectedRole state
                        onChange={handleRoleChange} // Handle role change
                        error={!!error}
                        helperText={error}
                        size="small"
                    >
                        {roles.map(role => (
                            <MenuItem key={role.id} value={role.id}>
                                {t(`common.roles.${role.code}`) as string}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            default:
                return null;
        }
    };

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <Grid item>
                <FormControl>
                    <InputLabel>{filterByLabel}</InputLabel>
                    <Select sx={{ height: 40 }} value={searchBy} onChange={handleSearchByChange} label={filterByLabel}>
                        <MenuItem value="email">{emailLabel}</MenuItem>
                        <MenuItem value="role">{roleLabel}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>{renderSearchInput()}</Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        height: 40,
                    }}
                    onClick={handleSearch}
                >
                    {searchButtonLabel}
                </Button>
            </Grid>
        </Grid>
    );
};

export default UserSearchFilter;
