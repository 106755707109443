import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Button, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSyncToken } from 'app/hooks';
import { setSuccessMessage, setErrorMessage } from 'app/state/message/messageSlice';
import { searchUserByEmail, searchUserInRole } from 'app/state/thunks/searchUser';
import { PageResponse, PagingRequest, UpdateUserRoleRequest, UserData } from '@oma-kala-shared/core/model';
import { updateRoleForUser } from 'app/state/thunks/updateUserRoles';
import useAuthData from 'app/hooks/useAuthData';
import { FilterCriteria, useUserManagementViewContext } from './ManagementViewContext';
import GenericTable from 'app/components/common/GenericTable';
import UserDetails, { UserDetailsRef } from 'app/components/user/UserDetails';
import { usePagination } from 'app/hooks/usePagination';
import EditIcon from '@mui/icons-material/Edit';

const UserSearchResult = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userData: currentUserData } = useAuthData();
    const { filterCriteria, setShowBackdrop, roles } = useUserManagementViewContext();
    const userDetailRef = useRef<UserDetailsRef | null>(null);
    const lastFilterCriterialRef = useRef<FilterCriteria | null>(null);
    const lastPageRequestRef = useRef<PagingRequest | null>(null);

    const { currentPage, pageSize, setApiResponse, totalItems, totalPages, handlePageChange, handlePageSizeChange, setCurrentPage } =
        usePagination<UserData>({
            initialPageSize: 10,
        });

    const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
    const [userInRoleResponse, setUserInRoleResponse] = useState<PageResponse<UserData> | null>(null);

    const usersInList = userInRoleResponse?.content || [];

    // API Tokens
    const [getUserByEmailToken, gettingUserByEmail] = useSyncToken({
        onSuccess: (userData: UserData) => {
            setSelectedUser(userData);
        },
    });

    const [getUserByRoleToken, gettingUserByRole] = useSyncToken({
        onSuccess: (response: PageResponse<UserData>) => {
            setUserInRoleResponse(response);
            setApiResponse(response);
        },
    });

    const [updateUserRoleToken, updatingUserRole] = useSyncToken({
        onSuccess: () => {
            dispatch(setSuccessMessage(t('common.message.save.success')));
            userDetailRef.current?.setDirty(false);
        },
        onError: message => dispatch(setErrorMessage(message)),
    });

    // Fetch Users Based on Filter Criteria
    const fetchUsers = useCallback(() => {
        if (!filterCriteria) return;

        if (filterCriteria.field === 'email') {
            dispatch(searchUserByEmail(getUserByEmailToken, filterCriteria.value));
            setUserInRoleResponse(null);
        } else if (filterCriteria.field === 'role') {
            const pageRequest = { page: currentPage, size: pageSize };

            // This will prevent making duplicate request when page request is the same and filter value not changed
            if (
                lastPageRequestRef.current &&
                lastPageRequestRef.current.page === currentPage &&
                lastPageRequestRef.current.size === pageSize &&
                lastFilterCriterialRef.current &&
                lastFilterCriterialRef.current.value === filterCriteria.value
            ) {
                return;
            }

            if (lastFilterCriterialRef.current && lastFilterCriterialRef.current.value !== filterCriteria.value) {
                setCurrentPage(0);
                pageRequest.page = 0;
            }

            dispatch(searchUserInRole(getUserByRoleToken, filterCriteria.value, pageRequest));
            lastPageRequestRef.current = pageRequest;
            setSelectedUser(null);
        }

        lastFilterCriterialRef.current = filterCriteria;
    }, [filterCriteria, currentPage, pageSize, dispatch, getUserByEmailToken, getUserByRoleToken]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        setShowBackdrop(gettingUserByEmail || gettingUserByRole || updatingUserRole);
    }, [gettingUserByEmail, gettingUserByRole, updatingUserRole, setShowBackdrop]);

    // Handlers
    const handleUserSelect = (user: UserData) => setSelectedUser(user);

    const handleCancel = () => setSelectedUser(null);

    const handleSave = (userData: UserData) => {
        const request: UpdateUserRoleRequest = {
            roles: userData.userRoles.map(role => role.roleId),
            requesterId: currentUserData!.id,
        };

        dispatch(updateRoleForUser(updateUserRoleToken, userData.id, request));

        if (filterCriteria?.field === 'role') {
            const pageRequest = { page: currentPage, size: pageSize };
            dispatch(searchUserInRole(getUserByRoleToken, filterCriteria.value, pageRequest));
        }
    };

    // Render Table Headers
    const headers = [t('common.name'), t('common.email'), ''];

    // Render Table Rows
    const renderRow = (user: UserData) => (
        <TableRow key={user.id}>
            <TableCell>
                {user.firstName} {user.lastName}
            </TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
                <Button onClick={() => handleUserSelect(user)}>
                    <EditIcon />
                </Button>
            </TableCell>
        </TableRow>
    );

    if (roles.length === 0) return null;

    return (
        <>
            {selectedUser ? (
                <Box sx={{ mt: 4 }}>
                    <UserDetails
                        ref={userDetailRef}
                        userData={selectedUser}
                        availableRoles={roles}
                        onSave={handleSave}
                        onCancel={handleCancel}
                    />
                </Box>
            ) : (
                usersInList.length > 0 && (
                    <Box sx={{ mt: 4 }}>
                        <GenericTable
                            headers={headers}
                            data={usersInList}
                            rowKey={user => user.id}
                            renderRow={renderRow}
                            totalPages={totalPages}
                            totalItems={totalItems}
                            currentPage={currentPage}
                            pageSize={pageSize}
                            onPageChange={page => handlePageChange(page - 1)}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </Box>
                )
            )}
        </>
    );
};

export default UserSearchResult;
