import React from 'react';
import { Box, Chip } from '@mui/material';

import { UserRole as SharedUserRole } from '@oma-kala-shared/core/model';
import { useSelector } from 'react-redux';
import { selectUserRoles } from '../../../state/user/userSlice';
import { useTranslation } from 'react-i18next';

export const UserRole = () => {
    const userRoles: SharedUserRole[] | null = useSelector(selectUserRoles);
    const { t } = useTranslation();

    if (!userRoles) {
        return null;
    }

    return (
        <Box>
            {userRoles.map(ur => (
                <Chip sx={{ marginRight: 1 }} color="primary" key={ur.roleId} label={t(`common.roles.${ur.code}`) as string} />
            ))}
        </Box>
    );
};

export default UserRole;
