import React, { useImperativeHandle, useRef } from 'react';
import {
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    KnownTaxonomyType,
    ChildCatchDetails,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import { GridValue, DYNAMIC_FIELD_TYPE, DynamicGridRef, FieldValueType, GridField } from 'app/components/common/dynamic-grid';

import moment from 'moment';
import { BulkCatchChildCatchDetails, ChildCatchGrid } from './child_catch/ChildCatchGrid';
import CatchDeclarationHelper, {
    DeclarationPropertyRequirements,
    GenerateFieldsFromRequirementsOptions,
} from 'app/components/declaration/helpers/CatchDeclarationHelper';
import TaxonomyCatchPropertyGrid from '../../TaxonomyCatchPropertyGrid';
import { StatisticsDetailRef } from '../StatisticsDetail';

type BulkCatchStatisticsDetailProp = {
    catchProperties: DeclarationCatchProperty[];
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    childCatches: ChildCatchDetails[];
    onValueChange: (latestValue: GridValue) => void;
};

const BulkCatchStatisticsDetail = React.forwardRef<StatisticsDetailRef, BulkCatchStatisticsDetailProp>(
    ({ catchProperties, childCatches, taxonomyRequirement, onValueChange }: BulkCatchStatisticsDetailProp, ref) => {
        const statisticDetailRef = useRef<GridValue | null>({});
        const catchPropertyGridRef = useRef<DynamicGridRef>(null);
        const childCatchGridRef = useRef<BulkCatchChildCatchDetails>({ childCatches: [], aggregateValue: {} });

        const { t } = useTranslation();

        const duplicateProperties = CatchDeclarationHelper.checkDuplicateProperties(taxonomyRequirement.requiredTaxonomyProperties);

        const adiposeFinCountProperty = taxonomyRequirement.requiredTaxonomyProperties.find(
            property => property.name === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT
        );

        /**
         * Need to set isBulkProperty to true for the adipose fin count and release count properties
         * so that they will be rendered in child catch
         */
        if (adiposeFinCountProperty) {
            const newRequirements = taxonomyRequirement.requiredTaxonomyProperties.map(property => {
                if (
                    [
                        DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT,
                        DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT,
                    ].includes(property.name)
                ) {
                    return {
                        ...property,
                        isBulkProperty: true,
                    };
                }

                return property;
            });

            taxonomyRequirement = {
                ...taxonomyRequirement,
                requiredTaxonomyProperties: newRequirements,
            };
        }

        /**
         * Filters the required taxonomy properties based on the presence of duplicate properties and bulk property status.
         * These requirements are used to generate the fields for the bulk catch statistic detail it self.
         *
         * Those requirements are duplicated or isBulkProperty is true will be rendered in the child catch grid.
         *
         * @constant
         * @type {Array}
         * @name bulkCatchRequirementProperties
         * @description If there are no duplicate properties, it returns all required taxonomy properties.
         *              Otherwise, it filters out properties that are bulk properties or are included in the duplicate properties list.
         *
         * @param {Array} duplicateProperties - List of property names that are considered duplicates.
         * @param {Object} taxonomyRequirement - Object containing the required taxonomy properties.
         * @param {Array} taxonomyRequirement.requiredTaxonomyProperties - List of required taxonomy properties.
         * @param {boolean} property.isBulkProperty - Indicates if the property is a bulk property.
         * @param {string} property.name - The name of the property.
         *
         * @returns {Array} - Filtered list of required taxonomy properties.
         */
        const bulkCatchRequirementProperties =
            duplicateProperties.length === 0
                ? taxonomyRequirement.requiredTaxonomyProperties
                : taxonomyRequirement.requiredTaxonomyProperties.filter(
                      property => !property.isBulkProperty && !duplicateProperties.includes(property.name)
                  );

        useImperativeHandle(ref, () => ({
            validate: () => validateStatisticDetail(),
            getValue: () => statisticDetailRef.current,
            getChildCatches: () => childCatchGridRef.current.childCatches,
            getDefaultValues: () => {
                return preDefinedValues;
            },
        }));

        const onChildCatchesChange = (latestCatchDetail: BulkCatchChildCatchDetails) => {
            childCatchGridRef.current = latestCatchDetail;
            const { aggregateValue } = latestCatchDetail;
            handleValueChange(aggregateValue);
        };

        const handleValueChange = (newGridValue: GridValue) => {
            statisticDetailRef.current = {
                ...(statisticDetailRef.current ?? {}),
                ...newGridValue,
            };
            validateStatisticDetail();
            onValueChange(statisticDetailRef.current);
        };

        const validateStatisticDetail = () => {
            let isValid = true;

            if (!catchPropertyGridRef.current?.validate()) {
                isValid = false;
            }

            // Bulk catch should have at least 2 child catches
            if (duplicateProperties.length > 0 && (childCatchGridRef.current?.childCatches ?? []).length < 2) {
                isValid = false;
            }

            return isValid;
        };

        const fieldFiltering = (property: DeclarationPropertyRequirements): boolean => {
            return CatchDeclarationHelper.isStatisticProperty(property.name);
        };

        const visibilityHandler = (property: DeclarationPropertyRequirements): boolean => {
            return true;
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            return t(`catchDeclaration.catchProperties.${property.name}`);
        };

        const fieldsPostHandler = (fields: Record<string, GridField>, fieldValues: Record<string, FieldValueType>) => {
            // Update the fields with the catch properties
            const { updatedFields, updatedValues } = CatchDeclarationHelper.updateFieldsWithCatchProperties(
                fields,
                fieldValues,
                catchProperties
            );

            statisticDetailRef.current = {
                ...statisticDetailRef.current,
                ...updatedValues,
            };

            return updatedFields;
        };

        const preDefinedValues: Record<string, FieldValueType> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 0,
            [DeclarationSpeciesRequirementPropertyName.COUNT]: 2,
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 0,
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 0,
        };

        const predefinedValidationRules: Record<string, string> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 'numeric|min:0',
            [DeclarationSpeciesRequirementPropertyName.COUNT]: 'numeric|min:2',
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 'required|greater_than:0',
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 'required|greater_than:0',
        };

        const options: GenerateFieldsFromRequirementsOptions = {
            requirements: bulkCatchRequirementProperties,
            fieldFiltering,
            visibilityHandler: visibilityHandler,
            fieldLabelHandler,
            predefinedDefaultValues: preDefinedValues,
            predefinedValidationRules,
        };

        return (
            <>
                <ChildCatchGrid
                    taxonomyRequirement={taxonomyRequirement}
                    childCatches={childCatches}
                    onChildCatchesChange={onChildCatchesChange}
                />
                <TaxonomyCatchPropertyGrid
                    ref={catchPropertyGridRef}
                    generateFieldsFromRequirementsOptions={options}
                    fieldsPostHandler={fieldsPostHandler}
                    fieldsSortOrder={[DeclarationSpeciesRequirementPropertyName.COUNT, DeclarationSpeciesRequirementPropertyName.WEIGHT]}
                    onValueChange={handleValueChange}
                />
            </>
        );
    }
);

BulkCatchStatisticsDetail.displayName = 'BulkCatchStatisticsDetail';

export default BulkCatchStatisticsDetail;
