import React, { useImperativeHandle, useState } from 'react';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Role, RoleCode, UserData, UserRole } from '@oma-kala-shared/core/model';
import { t } from 'i18next';
import RoleChip from './RoleChip';
import useDirtyCheck from '../../hooks/useDirtyCheck';

interface UserDetailsProps {
    userData: UserData;
    availableRoles: Role[];
    onSave: (userData: UserData) => void;
    onCancel: () => void;
}

export interface UserDetailsRef {
    setDirty: (isDirty: boolean) => void;
}

const UserDetails = React.forwardRef<UserDetailsRef, UserDetailsProps>(({ userData, availableRoles, onSave, onCancel }, ref) => {
    const { firstName, lastName, email, userRoles } = userData;
    const { value: tempRoles, setValue: setTempRoles, isDirty, resetDirty, setDirty } = useDirtyCheck(userRoles);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);

    const saveModalTitle = t('common.confirmation.save.title');
    const saveModalContent = t('common.confirmation.save.content');
    const cancelModalTitle = t('common.confirmation.cancel.title');
    const cancelModalContent = t('common.confirmation.cancel.content');
    const yesText = t('common.yes');
    const noText = t('common.no');
    const saveText = t('common.save');
    const cancelText = t('common.cancel');
    const rolesTitle = t('common.role');

    // TODO: Maybe this will come from backend later
    const unassignableRoles = [RoleCode.SVK_USER, RoleCode.RECREATIONAL_FISHER];

    useImperativeHandle(ref, () => ({ setDirty: (isDirty: boolean) => setDirty(isDirty) }));

    const isRoleAssigned = (roleId: string) => {
        return tempRoles.some(r => r.roleId === roleId);
    };

    const handleRemoveRole = (role: UserRole) => {
        setTempRoles(tempRoles.filter(r => r.roleId !== role.roleId));
    };

    const handleAddRole = (roleToAdd: Role) => {
        const { id, ...roleWithoutId } = roleToAdd;
        setTempRoles([...tempRoles, { ...roleWithoutId, roleId: id }]);
    };

    const handleToggleRole = (role: Role) => {
        const existingRole = tempRoles.find(r => r.roleId === role.id);
        if (existingRole) {
            handleRemoveRole(existingRole);
        } else {
            handleAddRole(role);
        }
    };

    const handleSave = () => {
        onSave({ ...userData, userRoles: tempRoles });
        resetDirty();
        setOpenSaveModal(false);
    };

    const handleCancel = () => {
        if (isDirty) {
            setOpenCancelModal(true);
        } else {
            onCancel();
        }
    };

    const confirmCancel = () => {
        onCancel();
        resetDirty();
        setOpenCancelModal(false);
    };

    // TODO: Maybe remove check for editor later, it should come from backend
    const hiddenRoles = [RoleCode.EDITOR];
    const assignableRoles = availableRoles.filter(role => !unassignableRoles.includes(role.code) && !hiddenRoles.includes(role.code));
    const unassignableRolesList = availableRoles.filter(role => unassignableRoles.includes(role.code) && !hiddenRoles.includes(role.code));

    // TODO: Fixed order based on role code, later it should come from backend
    const roleOrder = [RoleCode.RECREATIONAL_FISHER, RoleCode.SVK_USER, RoleCode.ORGANIZER, RoleCode.EDITOR, RoleCode.ADMIN];
    const fixedOrder = (a: Role, b: Role) => roleOrder.indexOf(a.code) - roleOrder.indexOf(b.code);

    return (
        <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
            <Typography variant="h6">{`${firstName} ${lastName}`}</Typography>
            <Typography variant="body2" color="textSecondary">
                {email}
            </Typography>
            <Box sx={{ mt: 3 }}>
                <Typography variant="h5">{rolesTitle}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                    {[...assignableRoles].sort(fixedOrder).map(role => {
                        const isAssigned = isRoleAssigned(role.id);
                        return (
                            <RoleChip
                                key={role.id}
                                role={role}
                                isAssigned={isAssigned}
                                isUnassignable={false}
                                onClick={() => handleToggleRole(role)}
                            />
                        );
                    })}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                    {[...unassignableRolesList].sort(fixedOrder).map(role => {
                        const isAssigned = isRoleAssigned(role.id);
                        return (
                            <RoleChip
                                key={role.id}
                                role={role}
                                isAssigned={isAssigned}
                                isUnassignable={true}
                                onClick={() => handleToggleRole(role)}
                            />
                        );
                    })}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 5, gap: 2 }}>
                    <Button disabled={!isDirty} onClick={() => setOpenSaveModal(true)} color="primary" variant="contained">
                        {saveText}
                    </Button>
                    <Button onClick={handleCancel} color="primary" sx={{ mr: 1 }} variant="outlined">
                        {cancelText}
                    </Button>
                </Box>
                <Dialog open={openSaveModal} onClose={() => setOpenSaveModal(false)}>
                    <DialogTitle>{saveModalTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{saveModalContent}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSave} color="primary" autoFocus variant="contained">
                            {yesText}
                        </Button>
                        <Button onClick={() => setOpenSaveModal(false)} color="primary" variant="outlined">
                            {noText}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openCancelModal} onClose={() => setOpenCancelModal(false)}>
                    <DialogTitle>{cancelModalTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{cancelModalContent}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={confirmCancel} color="primary" autoFocus variant="contained">
                            {yesText}
                        </Button>
                        <Button onClick={() => setOpenCancelModal(false)} color="primary" variant="outlined">
                            {noText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
});

UserDetails.displayName = 'UserDetails';

export default UserDetails;
