import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    ChildCatchDetails,
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import { GridValue, DynamicGridRef, FieldValueType } from 'app/components/common/dynamic-grid';
import { Box, Chip } from '@mui/material';
import SingleCatchStatisticsDetail from './single_catch/SingleCatchStatisticDetail';
import BulkCatchStatisticsDetail from './bulk_catch/BulkCatchStatisticDetail';

type StatisticsDetailProp = {
    childCatches: ChildCatchDetails[];
    catchProperties: DeclarationCatchProperty[];
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    onValueChange: (latestValue: GridValue) => void;
    onCatchTypeChange: () => void;
};

export type StatisticsDetailRef = Omit<DynamicGridRef, 'setError'> & {
    getChildCatches: () => ChildCatchDetails[];
    getDefaultValues: () => Record<string, FieldValueType>;
};

const StatisticsDetail = React.forwardRef<StatisticsDetailRef, StatisticsDetailProp>(
    ({ catchProperties, childCatches, taxonomyRequirement, onValueChange, onCatchTypeChange }: StatisticsDetailProp, ref) => {
        const statisticsGridRef = useRef<StatisticsDetailRef>(null);

        useImperativeHandle(ref, () => ({
            validate: () => {
                return statisticsGridRef.current!.validate();
            },
            getValue: () => {
                return statisticsGridRef.current!.getValue();
            },
            getChildCatches: () => {
                return statisticsGridRef.current!.getChildCatches() ?? [];
            },
            getDefaultValues: () => {
                return statisticsGridRef.current!.getDefaultValues();
            },
        }));

        const { t } = useTranslation();

        const countProperty: DeclarationCatchProperty | undefined = catchProperties.find(
            (property: DeclarationCatchProperty) => property.propertyName === DeclarationSpeciesRequirementPropertyName.COUNT
        );

        const [isSingleCatch, setIsSingleCatch] = useState(!countProperty || parseInt(countProperty.value) <= 1);

        useEffect(() => {
            onCatchTypeChange();
        }, [isSingleCatch]);

        const handleCatchTypeChange = (isSingleCatch: boolean) => {
            setIsSingleCatch(isSingleCatch);
        };

        return (
            <>
                <Box sx={{ marginTop: 2.5, marginBottom: 2 }}>
                    <Chip
                        sx={{
                            marginRight: 1,
                        }}
                        label={t('catchDeclaration.singleCatchType')}
                        onClick={() => handleCatchTypeChange(true)}
                        color={isSingleCatch ? 'primary' : undefined}
                    />
                    <Chip
                        label={t('catchDeclaration.bulkCatchType')}
                        color={!isSingleCatch ? 'primary' : undefined}
                        onClick={() => handleCatchTypeChange(false)}
                    />
                </Box>
                {isSingleCatch ? (
                    <SingleCatchStatisticsDetail
                        ref={statisticsGridRef}
                        catchProperties={catchProperties}
                        declarationTaxonomyRequirement={[]}
                        taxonomyRequirement={taxonomyRequirement}
                        onValueChange={onValueChange}
                    />
                ) : (
                    <BulkCatchStatisticsDetail
                        ref={statisticsGridRef}
                        catchProperties={catchProperties}
                        childCatches={childCatches}
                        declarationTaxonomyRequirement={[]}
                        taxonomyRequirement={taxonomyRequirement}
                        onValueChange={onValueChange}
                    />
                )}
            </>
        );
    }
);

StatisticsDetail.displayName = 'StatisticsDetail';

export default StatisticsDetail;
