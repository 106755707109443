/* eslint-disable require-jsdoc */
import {
    CatchDeclarationPropertyUnitConfiguration,
    ChildCatchDetails,
    DeclarationCatchDetails,
    DeclarationCatchProperty,
    DeclarationDetails,
    DeclarationFisherRequirementPropertyDetails,
    DeclarationSpeciesPropertyRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    UnitConfiguration,
} from '@oma-kala-shared/core/model';
import { DYNAMIC_FIELD_TYPE, FieldValueType, GridField, GridValue } from 'app/components/common/dynamic-grid';
import moment from 'moment';

export type GenerateFieldsFromRequirementsOptions = {
    requirements: DeclarationPropertyRequirements[];
    fieldFiltering?: (requirement: DeclarationPropertyRequirements) => boolean;
    predefinedDefaultValues?: Record<string, FieldValueType>;
    visibilityHandler?: (requirement: DeclarationPropertyRequirements) => boolean;
    fieldTypeHandler?: (requirement: DeclarationPropertyRequirements) => DYNAMIC_FIELD_TYPE;
    predefinedValidationRules?: Record<string, string>;
    fieldPostHandler?: (field: GridField) => GridField;
    fieldLabelHandler?: (requirement: DeclarationPropertyRequirements) => string;
    disabledFields?: string[];
};

class CatchDeclarationHelper {
    static getValidationRuleForType(type: string): string {
        switch (type.toLowerCase()) {
            case DYNAMIC_FIELD_TYPE.STRING:
                return 'required|min:1';
            case DYNAMIC_FIELD_TYPE.FLOAT:
                return 'numeric|min:0';
            case DYNAMIC_FIELD_TYPE.INTEGER:
                return 'numeric|min:0';
            default:
                return 'required';
        }
    }

    static getDefaultValueForType(type: string): FieldValueType {
        switch (type.toLowerCase()) {
            case DYNAMIC_FIELD_TYPE.STRING:
                return '';
            case DYNAMIC_FIELD_TYPE.FLOAT:
                return 0.0;
            case DYNAMIC_FIELD_TYPE.INTEGER:
                return 0;
            case DYNAMIC_FIELD_TYPE.DATE:
                return moment();
            default:
                return '';
        }
    }

    static generateCatchPropertiesFromGridValue = (
        requiredProperties: DeclarationSpeciesPropertyRequirementDetails[],
        existingCatchProperties: DeclarationCatchProperty[],
        gridValue: GridValue
    ): DeclarationCatchProperty[] => {
        return requiredProperties.map(requiredProperty => {
            // const value = gridValue![getFieldNameInGridValue(requiredProperty.name)];
            const value = gridValue![requiredProperty.name] ?? undefined;
            const existingProperty = existingCatchProperties.find(prop => prop.propertyName === requiredProperty.name);

            return {
                id: existingProperty?.id ?? null,
                idRequiredTaxonomyProperty: requiredProperty.id,
                value: value?.toString() ?? CatchDeclarationHelper.getDefaultValueForType(requiredProperty.dataType).toString(),
                propertyName: requiredProperty.name,
                dataType: requiredProperty.dataType,
                unit: requiredProperty.unit!,
            };
        });
    };

    static generateFieldsFromRequirements = (options: GenerateFieldsFromRequirementsOptions): [Record<string, GridField>, GridValue] => {
        const fields: Record<string, GridField> = {};
        const fieldValues: GridValue = {};
        const {
            requirements,
            fieldFiltering,
            predefinedDefaultValues,
            visibilityHandler,
            fieldTypeHandler,
            predefinedValidationRules,
            fieldPostHandler,
            fieldLabelHandler,
            disabledFields,
        } = options;

        requirements.forEach(requiredProperty => {
            if (fieldFiltering && fieldFiltering(requiredProperty) === false) {
                return;
            }

            let value = CatchDeclarationHelper.getDefaultValueForType(requiredProperty.dataType);
            if (predefinedDefaultValues && predefinedDefaultValues[requiredProperty.name] !== undefined) {
                value = predefinedDefaultValues[requiredProperty.name];
            }

            fieldValues[requiredProperty.name] = value;
            if (visibilityHandler && visibilityHandler(requiredProperty) === false) {
                return;
            }

            let fieldType: string = requiredProperty.dataType;

            if (fieldTypeHandler) {
                fieldType = fieldTypeHandler(requiredProperty) as string;
            }

            let validationRule: string = CatchDeclarationHelper.getValidationRuleForType(requiredProperty.dataType);

            if (predefinedValidationRules && predefinedValidationRules[requiredProperty.name]) {
                validationRule = predefinedValidationRules[requiredProperty.name];
            }

            let label: string = requiredProperty.name;

            if (fieldLabelHandler) {
                label = fieldLabelHandler(requiredProperty);
            }

            let disabled = false;

            if (disabledFields && disabledFields.includes(requiredProperty.name)) {
                disabled = true;
            }

            let field: GridField = {
                id: requiredProperty.id as number,
                type: fieldType as DYNAMIC_FIELD_TYPE,
                inputSize: '98%',
                containerProps: {
                    xs: 6,
                },
                name: requiredProperty.name,
                label,
                required: true,
                unit: requiredProperty.unit,
                disabled: disabled,
                value,
                validationRule,
            };

            // Handling optional for taxonomy required properties
            if ('isOptional' in requiredProperty && requiredProperty.isOptional === true) {
                field.required = false;
                field.validationRule = '';
            }

            if (requiredProperty.unit !== null) {
                field.unitConfiguration = CatchDeclarationPropertyUnitConfiguration[
                    requiredProperty.name as DeclarationSpeciesRequirementPropertyName
                ] as UnitConfiguration;
            }

            if (fieldPostHandler) {
                field = fieldPostHandler(field);
            }

            fields[requiredProperty.name] = field;
        });

        return [fields, fieldValues];
    };

    static checkDuplicateProperties = (properties: DeclarationSpeciesPropertyRequirementDetails[]): string[] => {
        const seenProperties: Set<string> = new Set();
        const propertyMap: Map<string, DeclarationSpeciesPropertyRequirementDetails> = new Map();

        const duplicates: string[] = [];

        properties.forEach(property => {
            if (seenProperties.has(property.name)) {
                const existingProperty = propertyMap.get(property.name);
                if (existingProperty && existingProperty.isBulkProperty !== property.isBulkProperty) {
                    duplicates.push(property.name);
                }
            } else {
                seenProperties.add(property.name);
                propertyMap.set(property.name, property);
            }
        });

        return duplicates;
    };

    static handleReleaseCountProperties = (gridValue: GridValue) => {
        if (gridValue !== null) {
            if (
                +gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT] ===
                +gridValue[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]
            ) {
                gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT] =
                    +gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT];
            } else {
                gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT] = 0;
            }
        }

        return gridValue;
    };

    static handleAdiposeProperties = (gridValue: GridValue) => {
        if (gridValue === null) {
            return gridValue;
        }

        const releaseCount = +gridValue[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT];
        const adiposeFinCount = +gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT] || 0;
        const adiposeFinCutCount = adiposeFinCount === 0 ? 1 : 0;
        const hasAdiposeFin = adiposeFinCount !== 0;

        gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_COUNT] = adiposeFinCutCount;

        if (hasAdiposeFin) {
            gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_RELEASE_COUNT] = 0;
            gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT] =
                releaseCount === adiposeFinCount ? adiposeFinCount : 0;
        } else {
            gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT] = 0;
            gridValue[DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_RELEASE_COUNT] =
                releaseCount === adiposeFinCutCount ? adiposeFinCutCount : 0;
        }

        return gridValue;
    };

    static isStatisticProperty = (name: string): boolean => {
        const detailsRequirements: string[] = [
            DeclarationSpeciesRequirementPropertyName.CATCH_DATE,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR,
        ];

        return !detailsRequirements.includes(name);
    };

    static updateFieldsWithCatchProperties = (
        fields: Record<string, GridField>,
        fieldValues: Record<string, FieldValueType>,
        catchProperties: DeclarationCatchProperty[]
    ): { updatedFields: Record<string, GridField>; updatedValues: Record<string, FieldValueType> } => {
        let updatedValues = {
            ...fieldValues,
        };

        if (catchProperties.length > 0) {
            catchProperties.forEach(property => {
                const name = property.propertyName?.toUpperCase() as string;

                if (fields[name]) {
                    let value = property.value;
                    if (property.dataType === DYNAMIC_FIELD_TYPE.DATE) {
                        value = moment.utc(property.value).toISOString();
                    }

                    if (name === DeclarationSpeciesRequirementPropertyName.COUNT) {
                        value = value.toString();
                    }

                    updatedValues = {
                        ...updatedValues,
                        [name]: value,
                    };

                    fields[name].value = value;
                }
            });
        }

        return { updatedFields: fields, updatedValues };
    };

    static prepareDeclarationForRequest(declaration: DeclarationDetails): DeclarationDetails {
        const normalizeCatches = declaration.catches.map(catchItem => {
            const newProperties = CatchDeclarationHelper.normalizeCatchPropertiesForRequest(catchItem.catchProperties);

            let newChildCatches = catchItem.childCatches;
            if (newChildCatches && newChildCatches.length > 0) {
                newChildCatches = newChildCatches.map(childCatch => {
                    const normalizedProperties = CatchDeclarationHelper.normalizeCatchPropertiesForRequest(childCatch.properties);
                    return {
                        ...childCatch,
                        catchProperties: normalizedProperties,
                        properties: normalizedProperties,
                    };
                });
            }

            return {
                ...catchItem,
                childCatches: newChildCatches,
                catchProperties: newProperties,
            };
        });

        return {
            ...declaration,
            catches: normalizeCatches,
        };
    }

    static normalizeCatchPropertiesForRequest = (properties: DeclarationCatchProperty[]): DeclarationCatchProperty[] => {
        const ignoreProperties = [
            DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT,
            DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT,
        ];

        return properties.filter(p => !ignoreProperties.includes(p.propertyName as DeclarationSpeciesRequirementPropertyName));
    };

    static handleAiposeFinForCatchDetails = (catchDetails: DeclarationCatchDetails) => {
        const newProperties = CatchDeclarationHelper.handleAdiposeFinProperties(catchDetails.catchProperties);
        return { ...catchDetails, catchProperties: newProperties };
    };

    static handleAiposeFinForChildCatchProperties = (catchDetails: ChildCatchDetails) => {
        const newProperties = CatchDeclarationHelper.handleAdiposeFinProperties(catchDetails.properties);
        return { ...catchDetails, catchProperties: newProperties, properties: newProperties };
    };

    private static handleAdiposeFinProperties = (properties: DeclarationCatchProperty[]): DeclarationCatchProperty[] => {
        const adiposeFinCutCountProperty = properties.find(
            property => property.propertyName === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_COUNT
        );

        const adiposeFinCountProperty = properties.find(
            property => property.propertyName === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT
        );

        // When ADIPOSE_FIN_CUT_COUNT is present, add ADIPOSE_FIN_COUNT property
        if (adiposeFinCutCountProperty && !adiposeFinCountProperty) {
            const countProperty: DeclarationCatchProperty | undefined = properties.find(
                (property: DeclarationCatchProperty) => property.propertyName === DeclarationSpeciesRequirementPropertyName.COUNT
            );

            const releaseCountProperty: DeclarationCatchProperty | undefined = properties.find(
                (property: DeclarationCatchProperty) => property.propertyName === DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT
            );

            const adiposeFinCutReleaseCountProperty = properties.find(
                property => property.propertyName === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_CUT_RELEASE_COUNT
            );

            const adiposeFinCount = parseInt(countProperty!.value) - parseInt(adiposeFinCutCountProperty.value);
            const adiposeFinReleasedCount = parseInt(releaseCountProperty!.value) - parseInt(adiposeFinCutReleaseCountProperty!.value);

            const newAdiposeFinCountProperty: DeclarationCatchProperty = {
                id: 'virtual-catch-property-1',
                idRequiredTaxonomyProperty: -1,
                value: adiposeFinCount.toString(),
                propertyName: DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT,
                dataType: 'integer',
                unit: undefined,
            };

            const newAdiposeFinReleaseCountProperty: DeclarationCatchProperty = {
                id: 'virtual-catch-property-2',
                idRequiredTaxonomyProperty: -2,
                value: adiposeFinReleasedCount.toString(),
                propertyName: DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT,
                dataType: 'integer',
                unit: undefined,
            };

            properties = [...properties, newAdiposeFinCountProperty, newAdiposeFinReleaseCountProperty];
        }

        return properties;
    };
}

export default CatchDeclarationHelper;

export type DeclarationPropertyRequirements = DeclarationSpeciesPropertyRequirementDetails | DeclarationFisherRequirementPropertyDetails;
