import {
    DeclarationCatchValidationError,
    DeclarationFisherRequirementPropertyName,
    DeclarationRegulation,
    FishingType,
    KnownGearType,
    KnownGearTypeProperty,
    KnownTaxonomyType,
    LayerCommonSettingName,
    MapLayer,
    TaxonomyTypePropertyName,
} from '@oma-kala-shared/core/model';
import { ResourceLanguage } from 'i18next';

import { DrawerFolderName } from 'app/components/layout/drawer/types';

/* eslint-disable max-len */
import { MessageType, NotificationStatus, OmaKalaLanguageCode, OmaKalaUserRole, RedirectView, RequestStatus } from 'app/model';
import { RouteName } from 'app/model/Route';

import { Translations } from './translations';

const translations: Translations & ResourceLanguage = {
    translations: {
        admin: {
            userManagement: {
                title: 'Käyttäjähallinta',
                searchPanel: {
                    filterBy: 'Suodata',
                },
            },
            notifications: {
                browse: {
                    title: 'Lähetetyt push-viestit',
                    description: 'Alla olevassa listassa näet aiemmin lähetetyt push-viestit sekä niiden tilan',
                    helperText: 'Laajenna push-viesti nähdäksesi kunkin viestin tilan eri kielillä sekä viestiin liittyvät muut tiedot.',
                    loaded: 'Push-viestit haettu onnistuneesti.',
                    loadError: 'Push-viestien haku epäonnistui - ole hyvä ja yritä uudestaan. {{ message }}.',
                    columns: {
                        createdAt: 'Luontiaika',
                        redirectView: 'Kohdenäkymä',
                        redirectId: 'Näkymän ID',
                        language: 'Kieli',
                        status: 'Tila',
                        title: 'Otsikko',
                        message: 'Viesti',
                    },
                },
                send: {
                    buttons: {
                        submit: 'Lähetä',
                    },
                    description: 'Lähetä uusi push-viesti Omakala-käyttäjille.',
                    helperText:
                        'Tämä viesti lähetetään vain käyttäjille, joiden asiointikieli Omakalassa on $t(translations:common:languages:{{ lang }}).',
                    input: {
                        redirectToViewLabel: 'Ohjaa käyttäjä tiettyyn näkymään sovelluksessa (vapaaehtoista).',
                        messageLabel: 'Viesti',
                        redirectViewLabel: 'Ohjaa näkymään',
                        redirectViewIdLabel: 'Ohjaa näkymän ID:hen',
                        roleGroupLabel: 'Rooliryhmät',
                        titleLabel: 'Otsikko',
                    },
                    notificationSent: 'Viesti lähetetty.',
                    notificationSendingError: 'Jotain meni pieleen viestin lähetyksessä - ole hyvä ja yritä uudestaan.',
                    redirectViews: {
                        [RedirectView.NEWS]: 'Tiedotteet',
                    },
                    requiredValuesMissing: 'Joitain vaadittuja arvoja puuttuu.',
                    title: 'Lähetä Push-viesti',
                },
                status: {
                    [NotificationStatus.CREATED]: 'Luotu',
                    [NotificationStatus.FAILED]: 'Epäonnistunut',
                    [NotificationStatus.SENT]: 'Lähetetty',
                    [NotificationStatus.IN_PROGRESS]: 'Käynnissä',
                },
                redirectView: {
                    [RedirectView.NEWS]: 'Tiedotteet',
                },
            },
        },
        common: {
            add: 'Lisää',
            update: 'Päivitä',
            yes: 'Kylla',
            no: 'Ei',
            clear: 'Tyhjennä',
            ok: 'Ok',
            close: 'Sulje',
            submit: 'Lähetä',
            mandatory: 'pakollinen',
            optional: 'vapaaehtoinen',
            email: 'Sähköposti',
            firstName: 'Etunimi',
            lastName: 'Sukunimi',
            name: 'Nimi',
            reload: 'Lataa uudelleen',
            return: 'Palaa',
            send: 'Lähetä',
            search: 'Hae',
            password: 'Salasana',
            confirmPassword: 'Vahvista salasana',
            passwordRules:
                'Salasanassa tulee olla vähintään {{ minLength }} merkkiä, ja sen tulee sisältää sekä isoja että pieniä kirjaimia, numeroita ja erikoismerkkejä',
            passwordsDoNotMatch: 'Salasanat eivät täsmää',
            requiredHelpText: 'Tämä arvo on pakollinen',
            birthDate: 'Syntymäpäivä',
            address: {
                country: 'Maa',
                placeOfResidence: 'Kotikunta',
                postalCode: 'Postinumero',
                postalTown: 'Postitoimipaikka',
                street: 'Postiosoite',
                address: 'Osoite',
            },
            phoneNumber: 'Puhelinumero',
            login: 'Kirjaudu',
            loginDelimiterText: 'Tai',
            loginWithSvk: 'Kirjaudu sisään SVK-tililläsi',
            svkTempIssue: {
                title: 'Varoitus',
                content:
                    'Toistaiseksi SVK-kirjautumismahdollisuus on käytettävissä vain sellaisille SVK-jäseneille, joilla ei ennestään ole Omakala-tiliä.\n\nNe SVK-jäsenet, joilla on jo Omakala-tili, ohjeistetaan käyttämään sitä kirjautuessaan Omakalaan.\n\nJatkossa tämä rajoitus poisttuu ja kaikki SVK-jäsenet pääsevät kirjautumaan SVK-tunnuksillaan.',
                cancel: 'Peruuta',
                continue: 'Ymmärrän, älä näytä enää',
                ok: 'Ok',
            },
            logout: 'Kirjaudu ulos',
            loginError: 'Sähköpostiosoite tai salasana ovat virheelliset. Tarkista kirjautumistietosi ja yritä uudelleen.',
            save: 'Tallenna',
            cancel: 'Peruuta',
            delete: 'Poista',
            back: 'Takaisin',
            date: 'Päivämäärä',
            languages: {
                [OmaKalaLanguageCode.EN]: 'Englanti',
                [OmaKalaLanguageCode.SV]: 'Ruotsi',
                [OmaKalaLanguageCode.FI]: 'Suomi',
                [OmaKalaLanguageCode.SE]: '#missing#',
            },
            loadMessage: {
                [RequestStatus.Failed]: 'Sisällön lataaminen epäonnistui',
                [RequestStatus.Loading]: 'Ladataan sisältöä...',
                [RequestStatus.NotInitiated]: 'Lataamista ei ole aloitettu',
                [RequestStatus.Successful]: 'Lataaminen onnistui',
            },
            mobile: {
                appStore: {
                    linkAltText: 'Sovelluksen App Store linkkinappi',
                    qrCodeAltText: 'App Store QR koodi',
                },
                playStore: {
                    linkAltText: 'Sovelluksen Play Store linkkinappi',
                    qrCodeAltText: 'Play Store QR koodi',
                },
            },
            role: 'Rooli',
            roles: {
                [OmaKalaUserRole.ADMIN]: 'Ylläpitäjä',
                [OmaKalaUserRole.RECREATIONAL_FISHER]: 'Vapaa-ajan kalastaja',
                [OmaKalaUserRole.EDITOR]: 'Tiedonmuokkaaja',
                [OmaKalaUserRole.ORGANIZER]: 'Kalastusmatkajärjestäjä',
                [OmaKalaUserRole.SVK_USER]: 'Svk-käyttäjä',
            },
            or: 'TAI',
            error: 'Odottamaton virhe',
            fishingType: {
                [FishingType.HOOK_AND_LINE]: 'Onginta',
                [FishingType.ANGLING]: 'Heittokalastus',
                [FishingType.FYKE_NETTING]: 'Rysäkalastus',
                [FishingType.GILLNETTING]: 'Verkkokalastus',
                [FishingType.ICE_FISHING]: 'Pilkintä',
                [FishingType.KATISKA]: 'Katiska- ja mertapyynti',
                [FishingType.LONGLINE]: 'Koukku- ja siimapyynti',
                [FishingType.LURE_FISHING]: 'Perhokalastus',
                [FishingType.TROLLING]: 'Uistelu',
                [FishingType.SEINE_NETTING]: 'Nuottakalastus',
            },
            form: {
                leavingNotification: 'Haluatko varmasti poistua? Kaikki syöttämäsi tiedot menetetään, mikäli et tallenna tai lähetä',
            },
            dialog: {
                messageType: {
                    [MessageType.INFO]: 'Tiedot',
                    [MessageType.ERROR]: 'Varoitus',
                    [MessageType.SUCCESS]: 'Menestys',
                },
                doNotShowAgain: 'Älä näytä tätä uudelleen',
            },
            validationErrors: {
                email: 'Virheellinen sähköpostiosoite',
            },
            confirmation: {
                save: {
                    title: 'Tallennuksen vahvistus',
                    content: 'Haluatko varmasti tallentaa muutokset?',
                },
                cancel: {
                    title: 'Peruutuksen vahvistus',
                    content: 'Haluatko varmasti peruuttaa? Tallentamattomat muutokset menetetään.',
                },
                userRoleChangeReload: {
                    title: 'Omakala Roolipäivitys',
                    content:
                        "Järjestelmänvalvoja on päivittänyt käyttäjäroolesi. Päivitä muutokset ja käytä uusia rooleja klikkaamalla 'Päivitä'. Jos peruutat, sinun on päivitettävä sivu manuaalisesti, jotta muutokset tulevat voimaan.",
                },
            },
            message: {
                save: {
                    success: 'Muutokset tallennettu onnistuneesti',
                    failed: 'Virhe tallennettaessa muutoksia',
                },
            },
        },
        passwordProperties: {
            forgotPassword: 'Unohtunut salasana?',
            validation: {
                description: 'Salasanan tulee sisältää:',
                digit: 'numeroita',
                length: 'vähintään {{ minLength }} merkkiä',
                lowercase: 'pieniä kirjaimia',
                special: 'erikoismerkkejä',
                uppercase: 'isoja kirjaimia',
                passwordConfirm: 'salasanan ja salasanan vahvistuksen on vastattava toisiaan',
                isNewPasswordDifferentThanNewOne: 'uusi salasana ei ole sama kuin nykyinen',
            },
            messages: {
                noUserFound: 'Sosiaaliturvatunnukseen liittyvää käyttäjää ei löytynyt.',
                identificationUnsuccessful: 'Tunnistaminen ei onnistunut. Yritä uudelleen.',
                passwordChanged: 'Salasanasi on onnistuneesti vaihdettu.',
                passwordNotChanged: 'Virhe salasanaa vaihdettaessa. Yritä uudelleen.',
            },
        },
        header: {
            navigation: {
                news: 'Tiedotteet',
                feedback: 'Anna palautetta',
                sendNotification: 'Lähetä',
                browseNotifications: 'Selaa',
            },
        },
        footer: {
            mainFooter: {
                omaKalaLinks: {
                    about: 'Tietoa palvelusta',
                    terms: 'Käyttöehdot',
                    privacyStatement: 'Tietosuojaseloste',
                    technicalRequirements: 'Tekniset vaatimukset',
                    cmsLogin: 'CMS-hallintatyökalu',
                    feedback: 'Anna palautetta',
                },
                links: {
                    link: 'Linkit',
                    privacyStatement: 'Tietosuojaseloste',
                    teamsLink: 'Saaristomeripilotin Teams-keskusteluryhmä',
                },
                lukeLinks: {
                    resourceCenter: 'Luonnonvarakeskus',
                    contact: 'Ota yhteyttä',
                    media: 'Medialle',
                },
            },
            secondaryFooter: {
                gov: 'Luonnonvarakeskus',
                access: 'Saavutettavuusseloste',
                support: 'Tekninen tuki ja palaute',
            },
        },
        home: {
            registrationInfo: 'Rekisteröityminen tapahtuu mobiilisovelluksessa',
            title: 'Etusivu',
        },
        feedback: {
            title: 'Palaute',
            header: 'Anna palautetta palvelusta',
            helpText:
                'Voit antaa palautetta palvelussa havaitsemistasi virheistä tai lähettää kehitysehdotuksia. Yhteystietojesi jättäminen on vapaaehtoista ja niitä käytetään ainoastaan mahdollisiin yhteydenottoihin tarvitessamme tarkennuksia palautteeseesi liittyen.',
            feedback: 'Palaute',
            successMessage: 'Palautteesi on lähetetty',
            errorMessage: 'Virhe palautteen lähettämisessä: {{ message }}',
            captcha: 'Varmennusteksti',
            captchaMessage:
                'Voit lähettää palautteen, kun olet kirjoittanut varmennustekstin. Varmennustekstiä ei kysytä, jos olet kirjautunut palveluun.',
            backendUnavailableMessage: 'Yhteyden muodostamisessa palvelimeen oli ongelmia. Jotkin toiminnot eivät ehkä toimi kunnolla.',
            email: 'Palautetta voi myös antaa sähköpostilla: ',
        },
        profile: {
            title: 'Omat tiedot',
            name: 'Nimi',
            alias: 'Aliasnimi',
            email: 'Sähköposti',
            address: 'Osoite',
            city: 'Kaupunki',
            postalCode: 'Postinumero',
            placeOfResidence: 'Kotikunta',
            phoneNumber: 'Puhelinnumero',
            successMessage: 'Tiedot päivitetty',
            errorMessage: 'Omien tietojen päivittämisessä tapahtui virhe',
            avatarIconAlt: 'Profiilivalikko',
            profileSections: {
                primaryInfo: {
                    title: 'Ensisijaiset tiedot',
                    description: 'Tarkista ja muokkaa ensisijaisia tietojasi',
                },
                changePassword: {
                    title: 'Vaihda salasana',
                    description: 'Sinut kirjaudutaan ulos salasanan vaihtamisen jälkeen',
                    externalIdentity: 'Voit vaihtaa salasanasi käymällä {{ provider }} -sivustolla',
                },
                userConsent: {
                    title: 'Käyttäjän suostumukset',
                },
                userRole: {
                    title: 'Käyttäjän roolit',
                },
                svkInfo: {
                    title: 'SVK-tiedot',
                    membership: {
                        title: 'Jäsenyyden tiedot (päivitetty {{time}})',
                        dataUpdateHint:
                            'Jäsentiedot päivitetään jokaisen SVK-identiteetillä tapahtuvan kirjautumisen yhteydessä. Seuraavan kerran Omakala saa päivitetyt jäsentiedot seuraavan uloskirjautumisen ja sen jälkeisen sisäänkirjautumisen jälkeen',
                        expiredDateInAvailable: 'Jäsenyyden päättymispäivä ei ole saatavilla',
                        validUntil: 'Jäsenyyden päättymispäivä: {{time}}',
                    },
                },
            },
        },
        news: {
            title: 'Tiedotteet',
            moreNews: 'Lisää tiedotteita',
            noMoreNews: 'Ei löytynyt enempää uutisia.',
            noExist: 'Uutisia ei ole olemassa.',
        },
        cookieDialog: {
            cookieConfiguration: 'Evästeiden määritys',
            description:
                'Omakala käyttää evästeitä eri tarkoituksiin. Jotta voit käyttää sivustoa, sinun tulee hyväksyä välttämättömät evästeet. ',
            essentialCookie:
                'Käytämme evästeitä verkkosivuston toimivuuden ja turvallisuuden takaamiseksi eri toiminnoissa, kuten kirjautumisessa ja sisällönhallinnassa. Evästeet ovat välttämättömiä ja tallentuvat aina automaattisesti.',
            analyticCookie:
                'Analyyttisiä evästeitä käytetään sivuston käytön ja latauksen seurantaan. Analytiikkatietoja käytetään sivuston ylläpitoon ja kehittämiseen. Lisäksi evästeitä käytetään Google Analyticsin evästeiden käyttökäytännön mukaisesti.',
            privacyLink: 'Tietosuojaseloste',
        },
        routes: {
            [RouteName.ABOUT]: 'Palvelusta',
            [RouteName.FEEDBACK]: 'Anna palautetta',
            [RouteName.HOME]: 'Koti',
            [RouteName.NEWS]: 'Uutiset',
            [RouteName.NOTIFICATIONS]: 'Ilmoitukset',
            [RouteName.PROFILE]: 'Tili',
            [RouteName.SEND_NOTIFICATION]: 'Lähetä ilmoituksia',
            [RouteName.TECHNICAL_REQUIREMENTS]: 'Tekniset vaatimukset',
            [RouteName.TERMS_AND_CONDITION]: 'Käyttöehdot',
            [RouteName.CMS_ITEM]: 'CMS',
            [RouteName.NEWS_ITEM]: 'Artikkeli',
            [RouteName.LOGIN]: 'Sisäänkirjautuminen',
            [RouteName.RESET_PASSWORD]: 'Salasanan palautus',
            [RouteName.REGISTRATION]: 'Register',
            [RouteName.GEAR]: 'Välineet',
            [RouteName.GEAR_ITEM]: 'Varusteet',
            [RouteName.CREATE_CATCH_DECLARATION]: 'Uuden saalisilmoituksen luominen',
            [RouteName.CATCH_DECLARATION_GROUP]: 'Saalisilmoitus {{- newline}} - Kalastusmatkajärjestäjä',
            [RouteName.CATCH_DECLARATION_PERSONAL]: 'Saalisilmoitus {{- newline}} - Henk. koht.',
            [RouteName.CATCH_DECLARATION]: 'Saalisilmoitus',
            [RouteName.SUPPORT]: 'Kayttäjätuki',
            [RouteName.PRIVACY_POLICY]: 'Tietosuojailmoitus',
            [RouteName.USER_MANAGEMENT]: 'Käyttäjähallinta',
        },
        drawerMenu: {
            folders: {
                [DrawerFolderName.LINKS]: 'Linkit',
                [DrawerFolderName.LUKE]: 'Luke',
                [DrawerFolderName.SERVICE_INFORMATION]: 'Tietoja palvelusta',
                [DrawerFolderName.CATCH_DECLARATION]: 'Saalisilmoitus',
                [DrawerFolderName.ADMIN]: 'Ylläpito',
                [DrawerFolderName.SUPPORT_AND_FEEDBACK]: 'Käyttäjätuki ja -palaute',
                [DrawerFolderName.PUSH_NOTIFICATIONS]: 'Push-ilmoitus',
                [DrawerFolderName.ACCOUNT]: 'Käyttäjätili',
            },
        },
        registration: {
            title: 'Rekisteröityminen',
            info: 'Registering requires strong authentication (Suomi.fi).',
            helpText: 'Valitse "Jatka" siirtyäksesi Suomi.fi-palveluun.',
            acceptTermsAndConditions: 'Hyväksyn',
            termsAndConditions: 'Käyttöehdot',
            alradyHaveAccount: 'Sinulla on jo tili',
            registration: 'Rekisteröinti',
            identificationUnsuccessful: 'Tunnistaminen epäonnistui',
            validation: {
                email: {
                    format: 'Väärä sähköpostin muoto',
                    empty: 'Sähköposti ei voi olla tyhjä',
                },
            },
            messages: {
                registrationSuccessful: 'Rekisteröityminen onnistui! Nyt voit kirjautua sisään.',
                registrationUnsuccessful: 'Rekisteröityminen ei onnistunut, yritä myöhemmin uudelleen.',
            },
            sections: {
                primaryInfo: {
                    title: 'Ensisijaiset tiedot',
                    description: 'Anna ensisijaiset tiedot',
                },
                contactInfo: {
                    title: 'Yhteystiedot',
                    description: 'Anna yhteystiedot',
                },
                password: {
                    title: 'Salasana',
                    description: 'Anna turvallinen salasana',
                },
            },
        },
        gear: {
            addNewGear: 'Lisää uusi väline',
            titleMyGear: 'Välineeni',
            titleNewGear: 'Uusi väline',
            titleEditGear: 'Muokkaa välinettä',
            titleGearType: 'Välinetyyppi',
            gearsNotFound: 'Omia välineitä ei löytynyt',
            gearIconAccessibilityLabel: 'Välineikoni',
            deletegearTitle: 'Poistetaanko väline?',
            allGear: 'Kaikki välineet',
            deleteGearMessage:
                'Poistettu väline säilyy edelleen tapahtumilla, joille se on tällä hetkellä valittuna, mutta et voi muokata välinettä tai valita sitä enää uusille tapahtumille.',
            editPropertyHeader:
                'Mikä on $t(translations:gear:gearType:{{ gearType }}:possessiveName) $t(translations:gear:gearTypeProperty:{{ propertyName }}:name)?',
            gearType: {
                [KnownGearType.ROD]: {
                    name: 'onkivapa',
                    possessiveName: 'onkivapasi',
                    defaultGearName: 'Oma onkivapa',
                },
                [KnownGearType.NET]: {
                    name: 'verkko',
                    possessiveName: 'verkkosi',
                    defaultGearName: 'Oma verkko',
                },
                [KnownGearType.TRAP]: {
                    name: 'pyydys',
                    possessiveName: 'pyydyksesi',
                    defaultGearName: 'Oma pyydys',
                },
                [KnownGearType.KATISKA]: {
                    name: 'katiska',
                    possessiveName: 'katiskasi',
                    defaultGearName: 'Oma katiska',
                },
                [KnownGearType.FYKE]: {
                    name: 'rysä',
                    possessiveName: 'rysäsi',
                    defaultGearName: 'Oma rysä',
                },
                [KnownGearType.SPINNING_ROD]: {
                    name: 'heittovapa',
                    possessiveName: 'heittovapasi',
                    defaultGearName: 'Oma heittovapa',
                },
                [KnownGearType.FLY_ROD]: {
                    name: 'perhovapa',
                    possessiveName: 'perhovapasi',
                    defaultGearName: 'Oma perhovapa',
                },
                [KnownGearType.TROLLING_ROD]: {
                    name: 'vetouistin',
                    possessiveName: 'vetouistimesi',
                    defaultGearName: 'Oma vetouistin',
                },
                [KnownGearType.SEINE]: {
                    name: 'nuotta',
                    possessiveName: 'nuottasi',
                    defaultGearName: 'Oma nuotta',
                },
                [KnownGearType.SINGLE_HOOK_AND_LINE]: {
                    name: 'syöttikoukku',
                    possessiveName: 'syöttikoukkusi',
                    defaultGearName: 'Oma syöttikoukku',
                },
                [KnownGearType.SPRING_LOADED_FISH_HOOK]: {
                    name: 'iskukoukku',
                    possessiveName: 'iskukoukkusi',
                    defaultGearName: 'Oma iskukoukku',
                },
                [KnownGearType.OTHER_HOOK_GEAR]: {
                    name: 'muu koukkupyydys',
                    possessiveName: 'koukkupyydyksesi',
                    defaultGearName: 'Oma koukkupyydys',
                },
                [KnownGearType.LONG_LINE]: {
                    name: 'pitkäsiima',
                    possessiveName: 'pitkäsiimasi',
                    defaultGearName: 'Oma pitkäsiima',
                },
                [KnownGearType.ICE_ROD]: {
                    name: 'pilkkivapa',
                    possessiveName: 'pilkkivapasi',
                    defaultGearName: 'Oma pilkkivapa',
                },
                [KnownGearType.OTHER]: {
                    name: 'muu',
                    possessiveName: 'välineesi',
                    defaultGearName: 'Oma väline',
                },
            },
            gearTypeProperty: {
                [KnownGearTypeProperty.HEIGHT]: {
                    name: 'korkeus',
                },
                [KnownGearTypeProperty.LENGTH]: {
                    name: 'pituus',
                },
                [KnownGearTypeProperty.LENGTH_SEINE_BAG]: {
                    name: 'perän pituus',
                },
                [KnownGearTypeProperty.NODE_SPACING]: {
                    name: 'solmuväli',
                },
                [KnownGearTypeProperty.NODE_SPACING_FYKE_BAG]: {
                    name: 'perän solmuväli',
                },
                [KnownGearTypeProperty.NODE_SPACING_SEINE_BAG]: {
                    name: 'perän solmuväli',
                },
                [KnownGearTypeProperty.WIRE_STRENGTH]: {
                    name: 'langanvahvuus',
                },
                [KnownGearTypeProperty.HOOK_COUNT]: {
                    name: 'koukkujen määrä',
                },
                [KnownGearTypeProperty.DESCRIPTION]: {
                    name: 'kuvaus',
                },
            },
            gearDeleted: 'Väline poistettu',
            gearUpdated: 'Väline päivitetty',
            gearSaved: 'Väline tallennettu',
            callFailed: 'Toimenpide epäonnistui. Ole hyvä ja yritä uudestaan.',
        },
        about: {
            pageTitle: 'Tietoja Omakalasta',
            downloadAndroid: 'Lataa Omakala Androidille',
            downloadIos: 'Lataa Omakala IOS:lle',
        },
        registrationPrompt: {
            text: 'Jatkaaksesi sinun pitää ensin tunnistautua suomi.fi -palvelussa ja tämän jälkeen rekisteröityä Omakala-käyttäjäksi. Sinut ohjataan tunnistautumiseen 5 sekunnin kuluttua',
        },
        errorDuringSvkLogin: {
            title: 'Kirjautuminen SVK:n tunnuksilla epäonnistui. Ota yhteyttä omakala@luke.fi.',
        },
        unknownSvkLoginError: {
            title: 'Odottamaton virhe kirjautumisessa. Ota yhteyttä omakala@luke.fi',
        },
        catchDeclaration: {
            validation: {
                greaterThan: ':attribute tulee olla suurempi kuin :greater_than',
            },
            list: {
                searchCondition: 'Saalisilmoitukset hakuehdolla:',
                showAll: 'Kaikki ilmoitukset',
                title: {
                    group: 'Saalisilmoitukset - Kalastusmatkajärjestäjä',
                    personal: 'Saalisilmoitukset - Henkilökohtainen',
                },
            },
            generalizedLocation: {
                generalizing: 'Valitsemaasi sijaintia karkeistetaan',
                generalizedError: 'Sijaintisi karkeistus ei jostain syystä onnistunut',
            },
            type: {
                group: 'Kalastusmatkajärjestäjän saalisilmoitus',
                personal: 'Henkilökohtainen saalisilmoitus',
            },
            FIRST_NAME: 'Etunimi',
            LAST_NAME: 'Sukunimi',
            BIRTH_DATE: 'Syntymäaika',
            STREET_ADDRESS: 'Katuosoite',
            MUNICIPALITY: 'Paikkakunta',
            COUNT: 'Kalastajien määrä',
            fishIcon: 'Kalakuvake',
            userId: 'Käyttäjätunnus',
            catchDeclarationId: 'Ilmoitus ID',
            windowText: 'Etsi ilmoituksen parametrillä',
            species: 'Laji',
            location: 'Pyyntialue',
            fishingMethod: 'Pyyntitapa',
            fisherGroupCount: 'Ryhmän',
            primaryInfo: {
                title: 'Saalisilmoitukset',
                description: 'Tarkastele ja muokkaa saalisilmoituksia',
            },
            newDeclaration: {
                title: {
                    group: 'Uusi saalisilmoitus - Kalastusmatkajärjestäjä',
                    personal: 'Uusi saalisilmoitus - Henkilökohtainen',
                },
                fisherInfoTitle: 'Ilmoittajan tiedot',
                createNewCatch: 'LISÄÄ ILMOITUKSEEN UUSI SAALIS',
                catchInfoTitle: 'Saalistiedot',
                catchListTitle: 'Saaliit',
                submitDeclaration: 'Ilmoituksen lähettäminen',
                additionalInfo: 'Lisätiedot',
                messages: {
                    validateErrorMessage: 'Jotain on pielessä, tarkista vielä',
                    releaseCountGreaterThanCountMessage: 'Vapautettujen lukumäärä ei voi olla suurempi kuin kokonaismäärä',
                    adiposeFinCutCountGreaterThanCountMessage: 'Rasvaevällisten yksilöiden määrä ei voi olla suurempi kuin kokonaismäärä',
                    invalidAdiposeFinRealeaseCountMessage:
                        'Rasvaevällisten yksilöiden vapautettujen määrä ei voi olla suurempi kuin vapautettujen yksilöiden kokonaismäärä tai rasvaevällisten määrä',
                },
                declarationCatchValidationError: {
                    [DeclarationCatchValidationError.DUPLICATE_CATCH]: 'Saalis on identtinen aiemmin lisätyn saaliin kanssa',
                    [DeclarationCatchValidationError.INVALID_REQUIREMENT_ID_REFERENCE]:
                        'Virheellinen tunnus - ota yhteyttä omakala@luke.fi',
                    [DeclarationCatchValidationError.INVALID_CATCH_REFERENCE_ID_FORMAT]:
                        'Virheellinen vaatimusviite - ota yhteyttä omakala@luke.fi',
                    [DeclarationCatchValidationError.INVALID_DATE_FORMAT]: 'Virheellinen päivämäärämuoto - ota yhteyttä omakala@luke.fi',
                },
            },
            editDeclaration: {
                title: 'Muokkaa ilmoitus',
                saveDeclaration: 'Ilmoituksen tallentaminen',
                editText:
                    'Tämä saalis on jo ilmoitettu saalisrekisteriin. Mikäli haluat muuttaa lakisääteiseen ilmoitukseen liittyviä tietoja, ole yhteydessä Luonnonvarakeskukseen.',
            },
            fisherProperties: {
                personal: {
                    [DeclarationFisherRequirementPropertyName.FIRST_NAME]: 'Etunimi',
                    [DeclarationFisherRequirementPropertyName.LAST_NAME]: 'Sukunimi',
                    [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: 'Paikkakunta',
                    [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: 'Syntymäaika',
                    [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: 'Katuosoite',
                    [DeclarationFisherRequirementPropertyName.COUNT]: 'jäsenten lukumäärä',
                },
                group: {
                    [DeclarationFisherRequirementPropertyName.FIRST_NAME]: '$t(catchDeclaration.fisherGroupFieldPrefix) etunimi',
                    [DeclarationFisherRequirementPropertyName.LAST_NAME]: '$t(catchDeclaration.fisherGroupFieldPrefix) sukunimi',
                    [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: '$t(catchDeclaration.fisherGroupFieldPrefix) paikkakunta',
                    [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: '$t(catchDeclaration.fisherGroupFieldPrefix) syntymäaika',
                    [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: '$t(catchDeclaration.fisherGroupFieldPrefix) katuosoite',
                    [DeclarationFisherRequirementPropertyName.COUNT]: 'Ryhmän jäsenten lukumäärä',
                },
            },
            catchProperties: {
                SPECIES: 'Laji',
                WEIGHT: 'Paino',
                LENGTH: 'Pituus',
                CATCH_DATE: 'Pyyntipäivämäärä',
                CATCH_GEAR: 'Pyyntimenetelmä',
                CATCH_GEAR_NODE_SPACING: 'Solmuväli',
                CATCH_GEAR_HEIGHT: 'Verkon korkeus',
                CATCH_GEAR_LENGTH: 'Verkon pituus',
                CATCH_GEAR_WIRE_STRENGTH: 'Langanvahvuus',
                COUNT: 'Lukumäärä',
                RELEASE_COUNT: 'Vapautettujen lukumäärä',
                // ADIPOSE_FIN_CUT_COUNT: 'Rasvaevällaikettu yksilöiden lukumäärä',
                // ADIPOSE_FIN_CUT_RELEASE_COUNT: 'Vapautettujen Rasvaevällaikettu yksilöiden lukumäärä',
                ADIPOSE_FIN_COUNT: 'Rasvaevällisten yksilöiden lukumäärä',
                ADIPOSE_FIN_RELEASE_COUNT: 'Vapautettujen rasvaevällisten yksilöiden lukumäärä',
            },
            fetchMoreRows: 'Näytä lisää',
            addNew: 'LISÄÄ UUSI ILMOITUS',
            resultsFound: 'Saalisilmoitusta löytyi',
            noResultsFound: 'Ei tuloksia',
            source: 'Lähde',
            automatic: 'Mobiili, automaatti ilmoitus',
            manual: 'Mobiili, manuaali-ilmoitus',
            webform: 'Web-sivu',
            newLocationSetInfo: 'Saaliin sijaintia on muutettu. Karkeistettu sijainti päivitetään tallennettaessa',
            noLocationSetInfo: 'Valitse sijainti kartalta. Se yleistetään tallentamisen jälkeen',
            fisherPersonalType: 'Henkilökohtainen',
            fisherGroupType: 'Ryhmä',
            singleCatchType: 'Yksittäissaalis',
            singleCatchRelease: 'Vapautettu',
            singleCatchAdiposeFin: 'Rasvaevällinen',
            singleCatchWeight: 'Paino',
            bulkCatchType: 'Massasaalis',
            deletionReason: 'Ilmoita saalisilmoituksen poistamisen syy',
            declarationCatchDetailDeleteAlert: 'Tämä saalis poistetaan seuraavan syyn vuoksi: {}',
            declarationCatchDetailUnsavedAlert: 'Tämä saalis lisätään ilmoitukseen tallennuksen jälkeen #missing#',
            reason: 'Syy',
            declarationDeleted: 'Ilmoitus on poistettu',
            declarationSaved: 'Ilmoitus on tallennettu onnistuneesti!',
            requestFailed: 'Yllättävä virhetilanne, yritä uudelleen tai ota yhteyttä omakala@luke.fi',
            fisherGroupFieldPrefix: 'Järjestäjän',
            notNeededEx: {
                DECLARER_UNDER_AGE: 'Ilmoitusta ei vaadita alle 16-vuotiailta',
                OUTSIDE_DECLARATION_AREA: 'Valitulla alueella ilmoittaminen on vapaaehtoista',
                ALREADY_DECLARED: 'Tyyppi "{}" on jo ilmoitettu',
                TAXONOMY_NOT_REQUIRED: 'Tämä laji tällä alueella ei vaadi lakisääteistä saalisilmoitusta.',
                NO_REQUIREMENTS_ACTIVE: 'Mikään ilmoitusvaatimus ei ole aktiivinen',
                OTHER: 'Tuntematon syy',
            },
            notifications: {
                delete: {
                    lukeEmployeeNotice:
                        'Saalisilmoitus on poistettu lakisääteisestä rekisteristä. Mikäli saalis oli kokonaan virheellinen se tulee poistaa myös henkilökohtaisesta saalislokista. Jos kyseessä on korjaus tietoihin, tulee tiedot korjata henkilökohtaiseen saalislokiin. \n\nMikäli käyttäjällä on automaattinen saalisilmoitus käytössä (joka on oletus), hänen tulee poistaa/korjata tiedot henkilökohtaiseen saalislokiinsa saman päivän aikana, koska muuten saalis ilmoitetaan uudelleen',
                },
            },
            needToUpdateProfile: 'Lähettääksesi ilmoituksia, sinun on päivitettävä profiilisi seuraavilla tiedoilla:',
            userShouldUseApp: 'Suosittelemme ilmoittamaan saaliit Omakala-sovelluksella',
            regulation: {
                [DeclarationRegulation.INVASIVE_SET_FREE]: 'Toivottavaa olisi, ettei vieraslajeja vapauteta',
                [DeclarationRegulation.ADIPOSE_FIN_SET_FREE]:
                    'Säädösten mukaan rasvaevällinen {{ species }} on vapautettava tietyillä alueilla',
            },
            waterRegion: 'Vesistöalue',
            optionalDeclarationNotis:
                'Tämä laji tällä alueella ei vaadi lakisääteistä saalisilmoitusta, mutta vapaaehtoisuuden pohjalta sellaisen voi tehdä.',
            optionalByAreaDeclarationNotis:
                'Tällä alueella ei vaadita lakisääteistä saalisilmoitusta, mutta vapaaehtoisuuden pohjalta sellaisen voi tehdä.',
        },
        taxonomyType: {
            [KnownTaxonomyType.ALLIS_SHAD]: 'Pilkkusilli',
            [KnownTaxonomyType.ALPINE_BULLHEAD]: 'Kirjoeväsimppu',
            [KnownTaxonomyType.ARCTIC_CHAR]: 'Nieriä',
            [KnownTaxonomyType.ASP]: 'Toutain',
            [KnownTaxonomyType.ATLANTIC_SALMON]: 'Lohi',
            [KnownTaxonomyType.ATLANTIC_STURGEON]: 'Sinisampi',
            [KnownTaxonomyType.BALTIC_HERRING]: 'Silakka',
            [KnownTaxonomyType.BELICA]: 'Allikkosalakka',
            [KnownTaxonomyType.BLACK_GOBY]: 'Mustatokko',
            [KnownTaxonomyType.BLEAK]: 'Salakka',
            [KnownTaxonomyType.BRILL]: 'Silokampela',
            [KnownTaxonomyType.BROADNOSED_PIPEFISH]: 'Särmäneula',
            [KnownTaxonomyType.BROOK_LAMPREY]: 'Pikkunahkiainen',
            [KnownTaxonomyType.BROOK_STICKLEBACK]: 'Viisipiikki',
            [KnownTaxonomyType.BROOK_TROUT]: 'Puronieriä',
            [KnownTaxonomyType.BROWN_BULLHEAD]: 'Piikkimonni',
            [KnownTaxonomyType.BROWN_TROUT]: 'Taimen',
            [KnownTaxonomyType.BURBOT]: 'Made',
            [KnownTaxonomyType.CHINESE_SLEEPER]: 'Rohmutokko',
            [KnownTaxonomyType.CHUB]: 'Turpa',
            [KnownTaxonomyType.COD]: 'Turska',
            [KnownTaxonomyType.COMMON_BREAM]: 'Lahna',
            [KnownTaxonomyType.COMMON_CARP]: 'Karppi',
            [KnownTaxonomyType.COMMON_DAB]: 'Hietakampela',
            [KnownTaxonomyType.COMMON_GOBY]: 'Liejutokko',
            [KnownTaxonomyType.COMMON_NASE]: 'Nokkasärki',
            [KnownTaxonomyType.COMMON_RUDD]: 'Sorva',
            [KnownTaxonomyType.COMMON_SEASNAIL]: 'Imukala',
            [KnownTaxonomyType.COMMON_WHITEFISH]: 'Siika',
            [KnownTaxonomyType.CRUCIAN_CARP]: 'Ruutana',
            [KnownTaxonomyType.DACE]: 'Seipi',
            [KnownTaxonomyType.EELPOUT]: 'Kivinilkka',
            [KnownTaxonomyType.EURASIAN_MINNOW]: 'Mutu',
            [KnownTaxonomyType.EUROPEAN_ANCHOVY]: 'Sardelli',
            [KnownTaxonomyType.EUROPEAN_BITTERLING]: 'Katkerokala',
            [KnownTaxonomyType.EUROPEAN_BULLHEAD]: 'Kivisimppu',
            [KnownTaxonomyType.EUROPEAN_EEL]: 'Ankerias',
            [KnownTaxonomyType.EUROPEAN_SEABASS]: 'Meribassi',
            [KnownTaxonomyType.FIFTEENSPINED_STICKLEBACK]: 'Vaskikala',
            [KnownTaxonomyType.FLOUNDER]: 'Kampela',
            [KnownTaxonomyType.FOURBEARD_ROCKLING]: 'Neliviiksimade',
            [KnownTaxonomyType.FOURHORN_SCULPIN]: 'Härkäsimppu',
            [KnownTaxonomyType.GARFISH]: 'Nokkakala',
            [KnownTaxonomyType.GOLDFISH]: 'Kultakala',
            [KnownTaxonomyType.GRAYLING]: 'Harjus',
            [KnownTaxonomyType.GREAT_SANDEEL]: 'Isotuulenkala',
            [KnownTaxonomyType.GUDGEON]: 'Törö',
            [KnownTaxonomyType.IDE]: 'Säyne',
            [KnownTaxonomyType.LAKE_TROUT]: 'Harmaanieriä',
            [KnownTaxonomyType.LAND_LOCKED_ATLANTIC_SALMON]: 'Järvilohi',
            [KnownTaxonomyType.LESSER_SANDEEL]: 'Pikkutuulenkala',
            [KnownTaxonomyType.LONGSPINED_BULLHEAD]: 'Piikkisimppu',
            [KnownTaxonomyType.LUMPSUCKER]: 'Rasvakala',
            [KnownTaxonomyType.MACKEREL]: 'Makrilli',
            [KnownTaxonomyType.NINESPINE_STICKLEBACK]: 'Kymmenpiikki',
            [KnownTaxonomyType.PELED]: 'Peledsiika',
            [KnownTaxonomyType.PERCH]: 'Ahven',
            [KnownTaxonomyType.PIKE]: 'Hauki',
            [KnownTaxonomyType.PIKE_PERCH]: 'Kuha',
            [KnownTaxonomyType.PINK_SALMON]: 'Kyttyrälohi',
            [KnownTaxonomyType.PRUSSIAN_CARP]: 'Hopearuutana',
            [KnownTaxonomyType.PUMPKINSEED]: 'Aurinkoahven',
            [KnownTaxonomyType.RAINBOW_TROUT]: 'Kirjolohi',
            [KnownTaxonomyType.RIVER_LAMPREY]: 'Nahkiainen',
            [KnownTaxonomyType.ROACH]: 'Särki',
            [KnownTaxonomyType.ROCK_GUNNEL]: 'Teisti',
            [KnownTaxonomyType.ROUND_GOBY]: 'Mustatäplätokko',
            [KnownTaxonomyType.RUFFE]: 'Kiiski',
            [KnownTaxonomyType.RUSSIAN_STURGEON]: 'Venäjänsampi',
            [KnownTaxonomyType.SAND_GOBY]: 'Hietatokko',
            [KnownTaxonomyType.SEA_LAMPREY]: 'Merinahkiainen',
            [KnownTaxonomyType.SHORTHORN_SCULPIN]: 'Isosimppu',
            [KnownTaxonomyType.SIBERIAN_STURGEON]: 'Siperiansampi',
            [KnownTaxonomyType.SICHEL]: 'Miekkasärki',
            [KnownTaxonomyType.SILVER_BREAM]: 'Pasuri',
            [KnownTaxonomyType.SMELT]: 'Kuore',
            [KnownTaxonomyType.SNAKE_BLENNY]: 'Elaska',
            [KnownTaxonomyType.SPINED_LOACH]: 'Rantanuoliainen',
            [KnownTaxonomyType.SPRAT]: 'Kilohaili',
            [KnownTaxonomyType.STONE_LOACH]: 'Kivennuoliainen',
            [KnownTaxonomyType.STRAIGHTNOSE_PIPEFISH]: 'Siloneula',
            [KnownTaxonomyType.STURGEON]: 'Sampi',
            [KnownTaxonomyType.TENCH]: 'Suutari',
            [KnownTaxonomyType.THREESPINE_STICKLEBACK]: 'Kolmipiikki',
            [KnownTaxonomyType.TURBOT]: 'Piikkikampela',
            [KnownTaxonomyType.TWAITE_SHAD]: 'Täpläsilli',
            [KnownTaxonomyType.TWO_SPOTTED_GOBY]: 'Seitsenruototokko',
            [KnownTaxonomyType.VENDACE]: 'Muikku',
            [KnownTaxonomyType.VIMBA_BREAM]: 'Vimpa',
            [KnownTaxonomyType.WELS_CATFISH]: 'Monni',
            [KnownTaxonomyType.WHITE_FINNED_GUDGEON]: 'Valkoevätörö',
            [KnownTaxonomyType.ZOPE]: 'Sulkava',
            [KnownTaxonomyType.OTHER]: 'Muut',
        },
        taxonomyTypeProperty: {
            [TaxonomyTypePropertyName.ADIPOSE_FIN_CUT]: {
                name: 'rasvaevä leikattu',
                bulkName: 'rasvaevä leikattu',
                estimatedName: 'rasvaevä leikattu',
            },
            [TaxonomyTypePropertyName.ADIPOSE_FIN]: {
                name: 'rasvaevä',
                bulkName: 'rasvaevä',
                estimatedName: 'rasvaevä',
            },
            [TaxonomyTypePropertyName.CATCH_AND_RELEASE]: {
                name: 'saalis vapautettu',
                bulkName: 'saalis vapautettu',
                estimatedName: 'saalis vapautettu',
            },
            [TaxonomyTypePropertyName.LENGTH]: {
                name: 'pituus',
                bulkName: 'kokonaispituus',
                estimatedName: 'arvioitu pituus',
            },
            // these three properties are only received for data validation,
            // and shouldn't be displayed to the user - which is why they're empty
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_1]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_2]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.MUST_RELEASE_NON_ADIPOSE_FIN]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.WEIGHT]: {
                name: 'paino',
                bulkName: 'kokonaispaino',
                estimatedName: 'arvioitu paino',
            },
        },
        map: {
            searchBar: {
                clearResults: 'Tyhjennä hakutulokset',
                label: 'Hae',
                noResultsFound: 'Hakutuloksia ei löytynyt',
                placeholder: 'Hae...',
                resultsFound: 'Löytyi {{ count }} hakutulosta',
                requestError: 'Hakua ei voida suorittaa. Taustajärjestelmään ei saada yhteyttä.',
                result: {
                    region: 'Alue',
                    subregion: 'Osa',
                    municipality: 'Kunta',
                    placeType: 'Tyyppi',
                    placeTypeDescription: 'Kuvaus',
                    placeTypeCategory: 'Kategoria',
                    placeTypeGroup: 'Ryhmä',
                    placeTypeSubgroup: 'Alaryhmä',
                },
            },
            layers: {
                [MapLayer.ACTIVITY]: { name: 'Kalastustapahtumat', settings: {} },
                [MapLayer.CATCH]: { name: 'Saalistapahtumat', settings: {} },
                [MapLayer.WFS_FISHING_RESTRICTIONS]: { name: 'Kalastusrajoitukset', settings: {} },
                [MapLayer.HIGHLIGHT_LAYER]: { name: 'Valitut kohteet', settings: {} },
                [MapLayer.POINT_OF_INTEREST]: { name: 'Kohdepisteet', settings: {} },
                [MapLayer.POSITION]: { name: 'Sijainti', settings: {} },
                [MapLayer.ROUTE]: { name: 'Reitti', settings: {} },
                [MapLayer.ORTHOPHOTO_LAYER]: { name: 'Ilmakuva', settings: {} },
                [MapLayer.VECTOR_LAYER]: { name: 'Yleiskartta', settings: {} },
                [MapLayer.TERRAIN_LAYER]: { name: 'Maastokartta', settings: {} },
                [MapLayer.DEPTH_CURVES]: { name: 'Syvyyskäyrät', settings: {} },
                [MapLayer.WMS_FISHING_RESTRICTIONS]: {
                    name: 'Kalastusrajoitukset',
                    settings: {
                        [LayerCommonSettingName.FROM_DATE]: 'Päivämäärästä',
                        [LayerCommonSettingName.TO_DATE]: 'Päivämäärään',
                        deviatingRestrictionsOnly: 'Pelkästään poikkeavat pyyntimitat ja rauhoitusajat',
                    },
                },
            },
            settings: {
                mapLayer: {
                    title: 'Karttatasojen asetukset',
                },
            },
        },
    },
};

export default translations;
