import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { getUserRolesLastFetched } from 'app/logic/UserSettingService';
import { UserRole } from 'app/model';
import { fetchUserRoles } from 'app/state/thunks';
import { selectUserRoles } from 'app/state/user/userSlice';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const RoleUpdateReloadDialog: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showReloadModal, setShowReloadModal] = useState<boolean>(false);
    const checkUserRoleIntervalIdentification = useRef<NodeJS.Timeout>();
    const userRoles: UserRole[] | null = useSelector(selectUserRoles);
    const userRolesRef = useRef<UserRole[] | null>(null);

    useEffect(() => {
        const refreshIntervalMins = 3;

        const timeoutIdentifier = setInterval(() => {
            const lastUserRolesFetchMoment = getUserRolesLastFetched();

            if (lastUserRolesFetchMoment === null || moment().diff(lastUserRolesFetchMoment, 'minutes') >= refreshIntervalMins) {
                dispatch(fetchUserRoles());
            }
        }, refreshIntervalMins * 1000);

        checkUserRoleIntervalIdentification.current = timeoutIdentifier;

        return () => {
            clearInterval(checkUserRoleIntervalIdentification.current);
        };
    }, []);

    useEffect(() => {
        if (userRoles === null) {
            return;
        }

        if (userRolesRef.current === null && userRoles !== null) {
            userRolesRef.current = userRoles;
            return;
        }

        if (userRolesRef.current?.length !== userRoles.length) {
            setShowReloadModal(true);
            userRolesRef.current = userRoles;
        }
    }, [userRoles]);

    const handleReload = () => {
        window.location.reload();
    };

    const onReloadDialogClose = () => {
        setShowReloadModal(false);
    };

    return (
        <Dialog open={showReloadModal} onClose={onReloadDialogClose}>
            <DialogTitle>{t('common.confirmation.userRoleChangeReload.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('common.confirmation.userRoleChangeReload.content', {
                        currentRoles: userRoles
                            ?.map(role => t(`common.roles.${role.code}`) as string)
                            .join(', ')
                            .substring(-1),
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReload} color="primary" autoFocus variant="contained">
                    {t('common.update')}
                </Button>
                <Button onClick={onReloadDialogClose} color="primary" variant="outlined">
                    {t('common.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoleUpdateReloadDialog;
