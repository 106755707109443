import {
    DeclarationCatchValidationError,
    DeclarationFisherRequirementPropertyName,
    DeclarationRegulation,
    FishingType,
    KnownGearType,
    KnownGearTypeProperty,
    KnownTaxonomyType,
    LayerCommonSettingName,
    MapLayer,
    TaxonomyTypePropertyName,
} from '@oma-kala-shared/core/model';
import { ResourceLanguage } from 'i18next';

import { DrawerFolderName } from 'app/components/layout/drawer/types';

/* eslint-disable max-len */
import { MessageType, NotificationStatus, OmaKalaLanguageCode, OmaKalaUserRole, RedirectView, RequestStatus } from 'app/model';
import { RouteName } from 'app/model/Route';

import { Translations } from './translations';

const translations: Translations & ResourceLanguage = {
    translations: {
        admin: {
            userManagement: {
                title: 'Användarhantering',
                searchPanel: {
                    filterBy: 'Filtrera efter',
                },
            },
            notifications: {
                browse: {
                    title: 'Existerande Push notifieringar',
                    description: 'Nedan kan du se existerande push-notifieringar och deras statusar.',
                    helperText:
                        'Expandera notifieringarna för att se dess status för olika språk, samt övriga detaljer relaterade till meddelandena.',
                    loaded: 'Push- notifieringar hämtade.',
                    loadError: 'Hämtning av push- notifieringar misslyckades - var god försök igen. {{ message }}.',
                    columns: {
                        createdAt: 'Skapad',
                        redirectView: 'Vy',
                        redirectId: 'Vy ID',
                        language: 'Språk',
                        status: 'Status',
                        title: 'Titel',
                        message: 'Meddelande',
                    },
                },
                send: {
                    buttons: {
                        submit: 'Skicka',
                    },
                    description: 'Skicka en ny push-notifiering till användare i Omakala.',
                    helperText:
                        'Denna notifiering skickas till användare som valt $t(translations:common:languages:{{ lang }}) som språk in Omakala.',
                    input: {
                        redirectToViewLabel: 'Länka användaren till en vy i applikationen (frivilligt).',
                        messageLabel: 'Meddelande',
                        redirectViewLabel: 'Skicka till vy',
                        redirectViewIdLabel: 'Skicka till vy ID',
                        roleGroupLabel: 'Rollgrupper',
                        titleLabel: 'Titel',
                    },
                    notificationSent: 'Notifiering skickades.',
                    notificationSendingError: 'Något gick fel vid sändning av notifieringen - var god försök igen.',
                    redirectViews: {
                        [RedirectView.NEWS]: 'Nyheter',
                    },
                    requiredValuesMissing: 'Obligatoriska värden saknas.',
                    title: 'Skicka Push notifiering',
                },
                status: {
                    [NotificationStatus.CREATED]: 'Skapad',
                    [NotificationStatus.FAILED]: 'Misslyckad',
                    [NotificationStatus.SENT]: 'Skickad',
                    [NotificationStatus.IN_PROGRESS]: 'Pågående',
                },
                redirectView: {
                    [RedirectView.NEWS]: 'Nyheter',
                },
            },
        },
        common: {
            update: 'Uppdatera',
            add: 'Lägg till',
            yes: 'Ja',
            no: 'Nej',
            clear: 'Rensa',
            ok: 'Ok',
            close: 'Stäng',
            submit: 'Skicka in',
            mandatory: 'obligatorisk',
            optional: 'frivillig',
            email: 'E-post',
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            name: 'Namn',
            reload: 'Ladda om',
            return: 'Återvänd',
            send: 'Skicka',
            search: 'Sök',
            password: 'Lösenord',
            confirmPassword: 'Bekräfta lösenord',
            passwordRules:
                'Ditt lösenord måste vara minst {{ minLength }} tecken långt och måste innehålla stora och små bokstäver, siffror, och specialtecken.',
            passwordsDoNotMatch: 'Lösenorden överensstämmer inte',
            requiredHelpText: 'Detta värde krävs',
            birthDate: 'Födelsedag',
            address: {
                country: 'Land',
                placeOfResidence: 'Hemort',
                postalCode: 'Postkod',
                postalTown: 'Postort',
                street: 'Gatuadress',
                address: 'Adress',
            },
            phoneNumber: 'Telefonnummer',
            login: 'Logga in',
            loginDelimiterText: 'Eller',
            loginWithSvk: 'Logga in med ditt SVK-konto',
            svkTempIssue: {
                title: 'Varning',
                content:
                    'För närvarande är SVK-inloggningsalternativet endast tillgängligt för SVK-medlemmar som inte redan har ett Omakala-konto.\n\nSVK-medlemmar som redan har ett Omakala-konto instrueras att använda det när de loggar in på Omakala.\n\nI framtiden kommer denna begränsning att tas bort och alla SVK-medlemmar kommer att kunna logga in med sina SVK-id:n.',
                cancel: 'Avbryt',
                continue: 'Förstår, visa inte längre',
                ok: 'Ok',
            },
            logout: 'Logga ut',
            loginError: 'E-postadressen eller lösenordsuppgifterna är felaktiga. Ange dina uppgifter igen och försök igen.',
            save: 'Spara',
            cancel: 'Avbryt',
            delete: 'Radera',
            back: 'Tillbaka',
            date: 'Datum',
            languages: {
                [OmaKalaLanguageCode.EN]: 'Engelska',
                [OmaKalaLanguageCode.SV]: 'Svenska',
                [OmaKalaLanguageCode.FI]: 'Finska',
                [OmaKalaLanguageCode.SE]: '#missing#',
            },
            loadMessage: {
                [RequestStatus.Failed]: 'Get gick inte att ladda innehåll',
                [RequestStatus.Loading]: 'Laddar innehåll...',
                [RequestStatus.NotInitiated]: 'Laddning har ine påbörjats',
                [RequestStatus.Successful]: 'Sidan har laddats',
            },
            mobile: {
                appStore: {
                    linkAltText: 'Knapp för länk till App store',
                    qrCodeAltText: 'QR kod till App Store',
                },
                playStore: {
                    linkAltText: 'Knapp för länk till Play Store',
                    qrCodeAltText: 'QR kod till Play Store',
                },
            },
            role: 'Roll',
            roles: {
                [OmaKalaUserRole.ADMIN]: 'Administratör',
                [OmaKalaUserRole.RECREATIONAL_FISHER]: 'Fritidsfiskare',
                [OmaKalaUserRole.EDITOR]: 'Editor',
                [OmaKalaUserRole.ORGANIZER]: 'Organisatör',
                [OmaKalaUserRole.SVK_USER]: 'Svk-användare',
            },
            or: 'ELLER',
            error: 'Ett okänt fel inträffade',
            fishingType: {
                [FishingType.HOOK_AND_LINE]: 'Mete',
                [FishingType.ANGLING]: 'Kastfiske',
                [FishingType.FYKE_NETTING]: 'Ryssja fiske',
                [FishingType.GILLNETTING]: 'Nätfiske',
                [FishingType.ICE_FISHING]: 'Pimpelfiske',
                [FishingType.KATISKA]: 'Mjärde',
                [FishingType.LONGLINE]: 'Fiske med krok',
                [FishingType.LURE_FISHING]: 'Flugfiske',
                [FishingType.TROLLING]: 'Trolling ',
                [FishingType.SEINE_NETTING]: 'Notfiske',
            },
            form: {
                leavingNotification:
                    'Är du säker på att du vill lämna? Alla ändringar kommer att gå förlorade om du inte sparar eller skickar in',
            },
            dialog: {
                messageType: {
                    [MessageType.INFO]: 'Information',
                    [MessageType.ERROR]: 'Varning',
                    [MessageType.SUCCESS]: 'Lyckades',
                },
                doNotShowAgain: 'Visa inte detta igen',
            },
            validationErrors: {
                email: 'Ugyldig e-postadresse',
            },
            confirmation: {
                save: {
                    title: 'Bekräfta sparning',
                    content: 'Är du säker på att du vill spara ändringarna?',
                },
                cancel: {
                    title: 'Bekräfta avbryt',
                    content: 'Är du säker på att du vill avbryta? Osparade ändringar kommer att gå förlorade.',
                },
                userRoleChangeReload: {
                    title: 'Omakala Rolluppdatering',
                    content:
                        "En administratör har uppdaterat dina användarroller. För att tillämpa ändringarna och få tillgång till dina nya roller, klicka på 'Uppdatera'. Om du väljer att avbryta, måste du manuellt uppdatera sidan för att tillämpa uppdateringarna.",
                },
            },
            message: {
                save: {
                    success: 'Ändringar sparade erfolgreich',
                    failed: 'Fel vid sparande av ändringar',
                },
            },
        },
        passwordProperties: {
            forgotPassword: 'Glömt lösenordet?',
            validation: {
                description: 'Lösenordet bör innehålla:',
                digit: 'Siffra',
                length: 'Minst {{ minLength }} tecken',
                lowercase: 'Liten bokstav',
                special: 'Specialtecken',
                uppercase: 'Stor bokstav',
                passwordConfirm: 'Lösenord och lösenordsbekräftelse ska stämma överens',
                isNewPasswordDifferentThanNewOne: 'Det nya lösenordet är inte detsamma som det nuvarande',
            },
            messages: {
                noUserFound: 'Ingen användare med koppling till ditt personnummer hittades.',
                identificationUnsuccessful: 'Identifieringen misslyckades. Försök igen.',
                passwordChanged: 'Ditt lösenord har ändrats framgångsrikt.',
                passwordNotChanged: 'Fel när du ändrade ditt lösenord. Försök igen.',
            },
        },
        header: {
            navigation: {
                news: 'Nyheter',
                feedback: 'Feedback',
                sendNotification: 'Skicka',
                browseNotifications: 'Bläddra',
            },
        },
        footer: {
            mainFooter: {
                omaKalaLinks: {
                    about: 'Om tjänsten',
                    terms: 'Villkor',
                    privacyStatement: 'Sekretesspolicy',
                    technicalRequirements: 'Tekniska krav',
                    cmsLogin: 'CMS Förvaltningsverktyg',
                    feedback: 'Ge feedback',
                },
                links: {
                    link: 'Länkar',
                    privacyStatement: 'Sekretesspolicy',
                    teamsLink: 'Teams diskussionsgrupp för Skärgårdshavets pilot',
                },
                lukeLinks: {
                    resourceCenter: 'Naturresursinstitutet',
                    contact: 'Ta kontakt',
                    media: 'För media',
                },
            },
            secondaryFooter: {
                gov: 'Naturresursinstitutet',
                access: 'Tillgänglighetsförklaring',
                support: 'Teknisk support och feedback',
            },
        },
        home: {
            registrationInfo: 'Registreringen sker i mobilappen',
            title: 'Hem',
        },
        feedback: {
            title: 'Feedback',
            header: 'Feedback på tjänsten',
            helpText:
                'Ge feedback på eventuella problem du har med tjänsten eller ge förslag på förbättringar. Kontaktuppgifter är frivilliga och kommer endast användas för att kontakta dig för förtydliganden gällande din feedback.',
            feedback: 'Meddelande',
            successMessage: 'Din feedback har skickats.',
            errorMessage: 'Det gick inte att skicka feedback: {{ message }}',
            captcha: 'Captcha',
            captchaMessage: 'Du kan skicka feedbacken efter att ha fyllt i captcha-texten. Captcha krävs inte av inloggade användare.',
            backendUnavailableMessage:
                'Det fanns ett problem med att upprätta en anslutning till servern. Vissa funktioner kanske inte fungerar korrekt.',
            email: 'Du kan även lämna feedback via e-mail på: ',
        },
        profile: {
            title: 'Min profil',
            name: 'Namn',
            alias: 'Alias',
            email: 'E-post',
            address: 'Adress',
            city: 'Stad',
            postalCode: 'Postkod',
            placeOfResidence: 'Hemort',
            phoneNumber: 'Telefonnummer',
            successMessage: 'Profil uppdaterad',
            errorMessage: 'Det gick inte att uppdatera profil: {{ message }}',
            avatarIconAlt: 'Profilmeny',
            profileSections: {
                primaryInfo: {
                    title: 'Primär information',
                    description: 'Visa och redigera din primära information',
                },
                changePassword: {
                    title: 'Ändra lösenord',
                    description: 'Du kommer att loggas ut när du har ändrat lösenordet',
                    externalIdentity: 'Du kan ändra ditt lösenord genom att besöka webbplatsen {{ provider }}',
                },
                userConsent: {
                    title: 'Användarens medgivanden',
                },
                userRole: {
                    title: 'Användarens roller',
                },
                svkInfo: {
                    title: 'SVK-information',
                    membership: {
                        title: 'Medlemsinformation (updaterades senast {{time}})',
                        dataUpdateHint:
                            'Medlemsdata uppdateras vid varje inloggning med SVK identitet. Nästa gång Omakala får uppdaterad information är efter utloggning och sedan en inloggning',
                        expiredDateInAvailable: 'Utgångsdatum för medlemskap ej tillgänglig',
                        validUntil: 'Utgångsdatum för medlemskap: {{time}}',
                    },
                },
            },
        },
        news: {
            title: 'Nyheter',
            moreNews: 'Fler nyheter',
            noMoreNews: 'Inga fler nyheter hittades.',
            noExist: 'Nyheter finns inte.',
        },
        cookieDialog: {
            cookieConfiguration: 'Cookie-konfiguration',
            description: 'Omakala använder cookies för olika ändamål. Användning av webbplatsen kräver att du godkänner cookierna. ',
            essentialCookie:
                'Vi använder cookies för webbplatsens funktionalitet och säkerhet i funktioner som inloggning och innehållshantering. Cookies är viktiga och sparas alltid automatiskt.',
            analyticCookie:
                'Analytiska cookies används för att spåra webbplatsanvändning och laddning. Analysinformation används för att underhålla och utveckla webbplatsen. Dessutom används cookies enligt Google Analytics policy för användning av cookies.',
            privacyLink: 'Sekretesspolicy',
        },
        routes: {
            [RouteName.ABOUT]: 'Om',
            [RouteName.FEEDBACK]: 'Feedback',
            [RouteName.HOME]: 'Hem',
            [RouteName.NEWS]: 'Nyheter',
            [RouteName.NOTIFICATIONS]: 'Aviseringar',
            [RouteName.PROFILE]: 'Konto',
            [RouteName.SEND_NOTIFICATION]: 'Skicka meddelanden',
            [RouteName.TECHNICAL_REQUIREMENTS]: 'Tekniska krav',
            [RouteName.TERMS_AND_CONDITION]: 'Villkor',
            [RouteName.CMS_ITEM]: 'CMS',
            [RouteName.NEWS_ITEM]: 'Artikel',
            [RouteName.LOGIN]: 'Logga in',
            [RouteName.RESET_PASSWORD]: 'Återställ lösenord',
            [RouteName.REGISTRATION]: 'Register',
            [RouteName.GEAR]: 'Utrustning',
            [RouteName.GEAR_ITEM]: 'Utrustning',
            [RouteName.CREATE_CATCH_DECLARATION]: 'Skapa en ny fångstdeklaration',
            [RouteName.CATCH_DECLARATION_GROUP]: 'Fångst deklaration {{- newline}} - Organisatör',
            [RouteName.CATCH_DECLARATION_PERSONAL]: 'Fångst deklaration {{- newline}} - Personlig',
            [RouteName.CATCH_DECLARATION]: 'Fångst deklaration',
            [RouteName.SUPPORT]: 'Stöd',
            [RouteName.PRIVACY_POLICY]: 'Integritestspolicy',
            [RouteName.USER_MANAGEMENT]: 'Användarhantering',
        },
        drawerMenu: {
            folders: {
                [DrawerFolderName.LINKS]: 'Länkar',
                [DrawerFolderName.LUKE]: 'Luke',
                [DrawerFolderName.SERVICE_INFORMATION]: 'Serviceinformation',
                [DrawerFolderName.CATCH_DECLARATION]: 'Fångst deklaration',
                [DrawerFolderName.ADMIN]: 'Administratör',
                [DrawerFolderName.SUPPORT_AND_FEEDBACK]: 'Stöd och feedback',
                [DrawerFolderName.PUSH_NOTIFICATIONS]: 'Push-meddelande',
                [DrawerFolderName.ACCOUNT]: 'Konto',
            },
        },
        registration: {
            title: 'Registrering',
            info: 'Registering requires strong authentication (Suomi.fi).',
            helpText: 'Klicka "Fortsätt" för att vidareförmedlas till Suomi.fi tjänsten.',
            acceptTermsAndConditions: 'Jag accepterar',
            termsAndConditions: 'villkoren',
            alradyHaveAccount: 'Du har redan ett konto',
            identificationUnsuccessful: 'Identifieringen misslyckades',
            registration: 'Registrering',
            validation: {
                email: {
                    format: 'Fel e-postformat',
                    empty: 'E-postmeddelandet kan inte vara tomt',
                },
            },
            messages: {
                registrationSuccessful: 'Registrering lyckad! Nu kan du logga in.',
                registrationUnsuccessful: 'Registreringen misslyckades, försök igen senare.',
            },
            sections: {
                primaryInfo: {
                    title: 'Primär information',
                    description: 'Ange primär information',
                },
                contactInfo: {
                    title: 'Kontaktinformation',
                    description: 'Ange kontaktinformation',
                },
                password: {
                    title: 'Lösenord',
                    description: 'Ange ett säkert lösenord',
                },
            },
        },
        gear: {
            addNewGear: 'Lägg till ny utrustning',
            titleMyGear: 'Min utrustning',
            titleNewGear: 'Ny utrustning',
            titleEditGear: 'Redigera utrustning',
            titleGearType: 'Typ av utrustning',
            gearsNotFound: 'Inga registrerade utrustningar hittades',
            gearIconAccessibilityLabel: 'Utrustningsikon',
            deletegearTitle: 'Ta bort utrustningen?',
            allGear: 'All utrustning',
            deleteGearMessage:
                'Den borttagna utrustningen kommer fortfarande att bevaras för de aktiviteter som den för närvarande är vald för, men du kommer inte att kunna redigera utrustningen eller välja den för nya aktiviteter.',
            editPropertyHeader:
                'Vad har ditt $t(translations:gear:gearType:{{ gearType }}:name) för $t(translations:gear:gearTypeProperty:{{ propertyName }}:name)?',
            gearType: {
                [KnownGearType.ROD]: {
                    name: 'fiskespö',
                    possessiveName: 'fiskespö',
                    defaultGearName: 'Mitt fiskespö',
                },
                [KnownGearType.NET]: {
                    name: 'nät',
                    possessiveName: 'nät',
                    defaultGearName: 'Mitt nät',
                },
                [KnownGearType.TRAP]: {
                    name: 'fälla',
                    possessiveName: 'fälla',
                    defaultGearName: 'Min fälla',
                },
                [KnownGearType.KATISKA]: {
                    name: 'katsa',
                    possessiveName: 'katsa',
                    defaultGearName: 'Min katsa',
                },
                [KnownGearType.FYKE]: {
                    name: 'ryssja',
                    possessiveName: 'ryssja',
                    defaultGearName: 'Min ryssja',
                },
                [KnownGearType.SPINNING_ROD]: {
                    name: 'haspelspö',
                    possessiveName: 'haspelspö',
                    defaultGearName: 'Mitt haspelspö',
                },
                [KnownGearType.FLY_ROD]: {
                    name: 'flugspö',
                    possessiveName: 'flugspö',
                    defaultGearName: 'Mitt flugspö',
                },
                [KnownGearType.TROLLING_ROD]: {
                    name: 'trollingspö',
                    possessiveName: 'trollingspö',
                    defaultGearName: 'Mitt trollingspö',
                },
                [KnownGearType.SEINE]: {
                    name: 'notvarp',
                    possessiveName: 'notvarp',
                    defaultGearName: 'Mitt notvarp',
                },
                [KnownGearType.SINGLE_HOOK_AND_LINE]: {
                    name: 'enkel krok och lina',
                    possessiveName: 'enkel krok och lina',
                    defaultGearName: 'Min enkla krok och lina',
                },
                [KnownGearType.SPRING_LOADED_FISH_HOOK]: {
                    name: 'fjäderbelastad fiskekrok',
                    possessiveName: 'fjäderbelastad fiskekrok',
                    defaultGearName: 'Min fjäderbelastad fiskekrok',
                },
                [KnownGearType.OTHER_HOOK_GEAR]: {
                    name: 'annan krok',
                    possessiveName: 'annan krok',
                    defaultGearName: 'Min krok',
                },
                [KnownGearType.LONG_LINE]: {
                    name: 'långrev',
                    possessiveName: 'långrev',
                    defaultGearName: 'Mitt långrev',
                },
                [KnownGearType.ICE_ROD]: {
                    name: 'pilkspö',
                    possessiveName: 'pilkspö',
                    defaultGearName: 'Mitt pilkspö',
                },
                [KnownGearType.OTHER]: {
                    name: 'annan',
                    possessiveName: 'utrustning',
                    defaultGearName: 'Annan utrustning',
                },
            },
            gearTypeProperty: {
                [KnownGearTypeProperty.HEIGHT]: {
                    name: 'höjd',
                },
                [KnownGearTypeProperty.LENGTH]: {
                    name: 'längd',
                },
                [KnownGearTypeProperty.LENGTH_SEINE_BAG]: {
                    name: 'bakdelens längd',
                },
                [KnownGearTypeProperty.NODE_SPACING]: {
                    name: 'maskstorlek',
                },
                [KnownGearTypeProperty.NODE_SPACING_FYKE_BAG]: {
                    name: 'påsens maskstorlek',
                },
                [KnownGearTypeProperty.NODE_SPACING_SEINE_BAG]: {
                    name: 'påsens maskstorlek',
                },
                [KnownGearTypeProperty.WIRE_STRENGTH]: {
                    name: 'trådstyrka',
                },
                [KnownGearTypeProperty.HOOK_COUNT]: {
                    name: 'krokmängd',
                },
                [KnownGearTypeProperty.DESCRIPTION]: {
                    name: 'beskrivning',
                },
            },
            gearDeleted: 'Utrustning borttagen',
            gearUpdated: 'Utrustning uppdaterad',
            gearSaved: 'Utrustning sparad',
            callFailed: 'Operationen misslyckades. Var god försök igen.',
        },
        about: {
            pageTitle: 'Om Omakala',
            downloadAndroid: 'Ladda ner Omakala för Android',
            downloadIos: 'Ladda ner Omakala för IOS',
        },
        registrationPrompt: {
            text: 'För att fortsätta måste du först identifiera dig på suomi.fi och därefter registrera ett Omakala-konto. Du kommer att omdirigeras till identifiering inom 5 sekunder.',
        },
        errorDuringSvkLogin: {
            title: 'Inloggning med SVK-referenser misslyckades. Kontakta omakala@luke.fi.',
        },
        unknownSvkLoginError: {
            title: 'Okänt fel vid inloggning. Kontakta omakala@luke.fi',
        },
        catchDeclaration: {
            validation: {
                greaterThan: ':attribute bör vara större än :greater_than',
            },
            list: {
                searchCondition: 'Fångsdeklarationer baserad på sökkriterium:',
                showAll: 'Alla fångstdeklarationer',
                title: {
                    group: 'Fångst deklaration - Fisketur organisatör',
                    personal: 'Fångst deklaration - Personlig',
                },
            },
            generalizedLocation: {
                generalizing: 'Platsen du valde kommer att generaliseras',
                generalizedError: 'Ett fel uppstod vid platsgeneralisering',
            },
            type: {
                group: 'Organisatör deklaration',
                personal: 'Personlig deklaration',
            },
            FIRST_NAME: 'Förnamn',
            LAST_NAME: 'Efternamn',
            BIRTH_DATE: 'Födelsedatum',
            STREET_ADDRESS: 'Adress',
            MUNICIPALITY: 'Komun',
            COUNT: 'Antal fiskare',
            fishIcon: 'Fiskikon',
            fisherGroupCount: 'Gruppens',
            catchProperties: {
                WEIGHT: 'Vikt',
                LENGTH: 'Längd',
                CATCH_DATE: 'Fångst datum',
                CATCH_GEAR: 'Fångst metod',
                CATCH_GEAR_NODE_SPACING: 'Maskstorlek',
                CATCH_GEAR_HEIGHT: 'Nätets höjd',
                CATCH_GEAR_LENGTH: 'Nätets längd',
                CATCH_GEAR_WIRE_STRENGTH: 'Trådstyrka',
                COUNT: 'Antal',
                RELEASE_COUNT: 'Antal frisläppta',
                // ADIPOSE_FIN_CUT_COUNT: 'Antal individer med fettfena cut',
                // ADIPOSE_FIN_CUT_RELEASE_COUNT: 'Antal återutsatta individer med fettfena cut',
                ADIPOSE_FIN_COUNT: 'Antal individer med fettfena',
                ADIPOSE_FIN_RELEASE_COUNT: 'Antal återutsatta individer med fettfena',
                SPECIES: 'Art',
            },

            userId: 'AnvändarID',
            catchDeclarationId: 'Deklarations-ID',
            windowText: 'Sök på deklarations parametrar',
            species: 'Art',
            location: 'Fåmgstområde',
            fishingMethod: 'Fiskemetod',
            primaryInfo: {
                title: 'Fångsdeklaration',
                description: 'Visa och redigera fångsdeklarationer',
            },
            newDeclaration: {
                title: {
                    group: 'Fångstdeklaration - Fisketur organisatör', // #fi#
                    personal: 'Fångstdeklaration - Personlig', // #fi#
                },
                fisherInfoTitle: 'Infomatoremsuppgifter',
                createNewCatch: 'Ny fångst',
                catchInfoTitle: 'Fångstinformation',
                catchListTitle: 'Fångster',
                submitDeclaration: 'Skicka in deklaration',
                additionalInfo: 'Ytterligare information',
                messages: {
                    validateErrorMessage: 'Något är fel, kontrollera igen',
                    releaseCountGreaterThanCountMessage: 'Antalet släppta fiskar kan inte vara högre än det totala beloppet',
                    adiposeFinCutCountGreaterThanCountMessage: 'Antalet individer med fettfenan kan inte vara större än det totala antalet',
                    invalidAdiposeFinRealeaseCountMessage:
                        'Antalet återutsatta individer med fettfena kan inte vara högre än antalet återutsatta individer eller antalet individer med fettfena',
                },
                declarationCatchValidationError: {
                    [DeclarationCatchValidationError.DUPLICATE_CATCH]: 'Fångsten är identisk med en tidigare tillagd fångst',
                    [DeclarationCatchValidationError.INVALID_REQUIREMENT_ID_REFERENCE]:
                        'Felaktig id-referens - vänligen kontakta omakala@luke.fi',
                    [DeclarationCatchValidationError.INVALID_CATCH_REFERENCE_ID_FORMAT]:
                        'Felaktig referens - vänligen kontakta omakala@luke.fi',
                    [DeclarationCatchValidationError.INVALID_DATE_FORMAT]: 'Felaktigt datumformat - vänligen kontakta omakala@luke.fi',
                },
            },
            editDeclaration: {
                title: 'Redigera fångst',
                saveDeclaration: 'Spara in deklaration',
                editText:
                    'Denna fångst har redan deklarerats till fångstregistret. Om du vill ändra information relaterad till deklarationen, vänligen kontakta Naturresursinstitutet Finland. (Luke)',
            },
            fisherProperties: {
                personal: {
                    [DeclarationFisherRequirementPropertyName.FIRST_NAME]: 'Förnamn',
                    [DeclarationFisherRequirementPropertyName.LAST_NAME]: 'Efternamn',
                    [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: 'Komun',
                    [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: 'Födelsedatum',
                    [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: 'Adress',
                    [DeclarationFisherRequirementPropertyName.COUNT]: 'Antal gruppmedlemmar',
                },
                group: {
                    [DeclarationFisherRequirementPropertyName.FIRST_NAME]: '$t(catchDeclaration.fisherGroupFieldPrefix) förnamn',
                    [DeclarationFisherRequirementPropertyName.LAST_NAME]: '$t(catchDeclaration.fisherGroupFieldPrefix) efternamn',
                    [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: '$t(catchDeclaration.fisherGroupFieldPrefix) komun',
                    [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: '$t(catchDeclaration.fisherGroupFieldPrefix) födelsedatum',
                    [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: '$t(catchDeclaration.fisherGroupFieldPrefix) adress',
                    [DeclarationFisherRequirementPropertyName.COUNT]: 'Antal gruppmedlemmar',
                },
            },
            newLocationSetInfo: 'Fångstens plats har ändrats. Den ungefärliga platsen uppdateras vid lagring',
            noLocationSetInfo: 'Välj en plats på kartan. Platsen kommer att generaliseras efter att du sparat',
            fisherPersonalType: 'Personlig',
            fisherGroupType: 'Gruppbaserad',
            singleCatchType: 'Enskild fångst',
            singleCatchRelease: 'Frisläppta',
            singleCatchAdiposeFin: 'Med fettfena',
            singleCatchWeight: 'Vikt',
            bulkCatchType: 'Massfångst',
            fetchMoreRows: 'Visa fler',
            addNew: 'Lägg till',
            resultsFound: 'resultat hittades',
            noResultsFound: 'Inga resultat hittades',
            source: 'Källa',
            automatic: 'Mobil, automatisk deklaration',
            manual: 'Mobil, manuell deklaration',
            webform: 'Webbsida',
            deletionReason: 'Vänligen ange anledningen till borttagning av deklaration',
            declarationCatchDetailDeleteAlert: 'Den här fångsten tas bort pga. följande anledningar: {}',
            declarationCatchDetailUnsavedAlert: 'Denna fångst kommer att läggas till i deklarationen efter att den har sparats #missing#',
            reason: 'Anledning',
            declarationDeleted: 'Deklarationen har tagits bort',
            declarationSaved: 'Deklarationen har sparats!',
            requestFailed: 'Det gick inte att lagra ändringarna. Kontakta omakala@luke.fi',
            fisherGroupFieldPrefix: 'Arrangör',
            notNeededEx: {
                DECLARER_UNDER_AGE: 'Deklaration krävs inte för personer under 16 år',
                OUTSIDE_DECLARATION_AREA: 'Deklaration inom det valda området är frivilligt',
                ALREADY_DECLARED: 'Arten "{}" är redan deklarerad',
                TAXONOMY_NOT_REQUIRED: 'Denna art i detta område kräver ingen laglig fångstdeklaration',
                NO_REQUIREMENTS_ACTIVE: 'Inga deklarationskrav är aktiva',
                OTHER: 'Okänd anledning',
            },
            notifications: {
                delete: {
                    lukeEmployeeNotice:
                        'Fångstrapporten har tagits bort från fångstregistret. Om fångsten var helt felaktig bör den också tas bort från den personliga fångstdagboken. Om det är en rättelse i några av detaljerna, ska de korrigeras i den personliga fångstdagboken. \n\nOm användaren har automatisk fångstrapportering aktiverad (vilket är standard), måste de ta bort/korrigera informationen i sin personliga fångstdagbok samma dag, annars kommer fångsten att rapporteras igen',
                },
            },
            needToUpdateProfile: 'För att skicka in deklarationer måste du uppdatera din profil med dessa egenskaper:',
            userShouldUseApp: 'Vi uppmanar dig att deklarera fångster med Omakala-appen',
            regulation: {
                [DeclarationRegulation.INVASIVE_SET_FREE]: 'Det skulle vara önskvärt att invasiva arter inte släpps ut',
                [DeclarationRegulation.ADIPOSE_FIN_SET_FREE]:
                    'Enligt föreskrifterna måste {{ species }} med en fettfena släppas ut i vissa områden',
            },
            waterRegion: 'Vattenområde',
            optionalDeclarationNotis:
                'Denna art i detta område kräver ingen laglig fångstdeklaration. Du kan fortfarande fortsätta med en frivillig deklaration.',
            optionalByAreaDeclarationNotis:
                'Detta område kräver ingen laglig fångstdeklaration. Du kan fortfarande fortsätta med en frivillig deklaration.',
        },
        taxonomyType: {
            [KnownTaxonomyType.ALLIS_SHAD]: 'Majfisk',
            [KnownTaxonomyType.ALPINE_BULLHEAD]: 'Bergsimpa',
            [KnownTaxonomyType.ARCTIC_CHAR]: 'Röding',
            [KnownTaxonomyType.ASP]: 'Asp',
            [KnownTaxonomyType.ATLANTIC_SALMON]: 'Lax',
            [KnownTaxonomyType.ATLANTIC_STURGEON]: 'Atlantisk stör',
            [KnownTaxonomyType.BALTIC_HERRING]: 'Strömming',
            [KnownTaxonomyType.BELICA]: 'Groplöja',
            [KnownTaxonomyType.BLACK_GOBY]: 'Svart smörbult',
            [KnownTaxonomyType.BLEAK]: 'Löja',
            [KnownTaxonomyType.BRILL]: 'Slätvar',
            [KnownTaxonomyType.BROADNOSED_PIPEFISH]: 'Tångsnälla',
            [KnownTaxonomyType.BROOK_LAMPREY]: 'Bäcknejonöga',
            [KnownTaxonomyType.BROOK_STICKLEBACK]: 'Bäckspigg',
            [KnownTaxonomyType.BROOK_TROUT]: 'Bäckröding',
            [KnownTaxonomyType.BROWN_BULLHEAD]: 'Dvärgmal',
            [KnownTaxonomyType.BROWN_TROUT]: 'Öring',
            [KnownTaxonomyType.BURBOT]: 'Lake',
            [KnownTaxonomyType.CHINESE_SLEEPER]: 'Amursömnfisk',
            [KnownTaxonomyType.CHUB]: 'Färna',
            [KnownTaxonomyType.COD]: 'Torsk',
            [KnownTaxonomyType.COMMON_BREAM]: 'Braxen',
            [KnownTaxonomyType.COMMON_CARP]: 'Karp',
            [KnownTaxonomyType.COMMON_DAB]: 'Sandskädda',
            [KnownTaxonomyType.COMMON_GOBY]: 'Lerstubb',
            [KnownTaxonomyType.COMMON_NASE]: 'Noskarp',
            [KnownTaxonomyType.COMMON_RUDD]: 'Sarv',
            [KnownTaxonomyType.COMMON_SEASNAIL]: 'Större ringbuk',
            [KnownTaxonomyType.COMMON_WHITEFISH]: 'Sik',
            [KnownTaxonomyType.CRUCIAN_CARP]: 'Ruda',
            [KnownTaxonomyType.DACE]: 'Stäm',
            [KnownTaxonomyType.EELPOUT]: 'Tånglake',
            [KnownTaxonomyType.EURASIAN_MINNOW]: 'Elritsa',
            [KnownTaxonomyType.EUROPEAN_ANCHOVY]: 'Ansjovis',
            [KnownTaxonomyType.EUROPEAN_BITTERLING]: 'Bitterling',
            [KnownTaxonomyType.EUROPEAN_BULLHEAD]: 'Stensimpa',
            [KnownTaxonomyType.EUROPEAN_EEL]: 'Ål',
            [KnownTaxonomyType.EUROPEAN_SEABASS]: 'Havsabborre',
            [KnownTaxonomyType.FIFTEENSPINED_STICKLEBACK]: 'Tångspigg',
            [KnownTaxonomyType.FLOUNDER]: 'Skrubbskädda',
            [KnownTaxonomyType.FOURBEARD_ROCKLING]: 'Fyrtömmad skärlånga',
            [KnownTaxonomyType.FOURHORN_SCULPIN]: 'Hornsimpa',
            [KnownTaxonomyType.GARFISH]: 'Näbbgädda',
            [KnownTaxonomyType.GOLDFISH]: 'Guldfisk',
            [KnownTaxonomyType.GRAYLING]: 'Harr',
            [KnownTaxonomyType.GREAT_SANDEEL]: 'Tobiskung',
            [KnownTaxonomyType.GUDGEON]: 'Sandkrypare',
            [KnownTaxonomyType.IDE]: 'Id',
            [KnownTaxonomyType.LAKE_TROUT]: 'Kanadaröding',
            [KnownTaxonomyType.LAND_LOCKED_ATLANTIC_SALMON]: 'Insjölax',
            [KnownTaxonomyType.LESSER_SANDEEL]: 'Kusttobis',
            [KnownTaxonomyType.LONGSPINED_BULLHEAD]: 'Oxsimpa',
            [KnownTaxonomyType.LUMPSUCKER]: 'Sjurygg',
            [KnownTaxonomyType.MACKEREL]: 'Makrill',
            [KnownTaxonomyType.NINESPINE_STICKLEBACK]: 'Småspigg',
            [KnownTaxonomyType.PELED]: 'Peledsik',
            [KnownTaxonomyType.PERCH]: 'Abborre',
            [KnownTaxonomyType.PIKE]: 'Gädda',
            [KnownTaxonomyType.PIKE_PERCH]: 'Gös',
            [KnownTaxonomyType.PINK_SALMON]: 'Puckellax',
            [KnownTaxonomyType.PRUSSIAN_CARP]: 'Silverruda',
            [KnownTaxonomyType.PUMPKINSEED]: 'Solabborre',
            [KnownTaxonomyType.RAINBOW_TROUT]: 'Regnbåge',
            [KnownTaxonomyType.RIVER_LAMPREY]: 'Flodnejonöga',
            [KnownTaxonomyType.ROACH]: 'Mört',
            [KnownTaxonomyType.ROCK_GUNNEL]: 'Tejstefisk',
            [KnownTaxonomyType.ROUND_GOBY]: 'Svartmunnad smörbult',
            [KnownTaxonomyType.RUFFE]: 'Gers',
            [KnownTaxonomyType.RUSSIAN_STURGEON]: 'Rysk stör',
            [KnownTaxonomyType.SAND_GOBY]: 'Sandstubb',
            [KnownTaxonomyType.SEA_LAMPREY]: 'Havsnejonöga',
            [KnownTaxonomyType.SHORTHORN_SCULPIN]: 'Rötsimpa',
            [KnownTaxonomyType.SIBERIAN_STURGEON]: 'Sibirisk stör',
            [KnownTaxonomyType.SICHEL]: 'Skärkniv',
            [KnownTaxonomyType.SILVER_BREAM]: 'Björkna',
            [KnownTaxonomyType.SMELT]: 'Nors',
            [KnownTaxonomyType.SNAKE_BLENNY]: 'Spetslångebarn',
            [KnownTaxonomyType.SPINED_LOACH]: 'Nissöga',
            [KnownTaxonomyType.SPRAT]: 'Skarpsill',
            [KnownTaxonomyType.STONE_LOACH]: 'Grönling',
            [KnownTaxonomyType.STRAIGHTNOSE_PIPEFISH]: 'Mindre havsnål',
            [KnownTaxonomyType.STURGEON]: 'Stör',
            [KnownTaxonomyType.TENCH]: 'Sutare',
            [KnownTaxonomyType.THREESPINE_STICKLEBACK]: 'Storspigg',
            [KnownTaxonomyType.TURBOT]: 'Piggvar',
            [KnownTaxonomyType.TWAITE_SHAD]: 'Staksill',
            [KnownTaxonomyType.TWO_SPOTTED_GOBY]: 'Sjustrålig smörbult',
            [KnownTaxonomyType.VENDACE]: 'Siklöja',
            [KnownTaxonomyType.VIMBA_BREAM]: 'Vimma',
            [KnownTaxonomyType.WELS_CATFISH]: 'Mal',
            [KnownTaxonomyType.WHITE_FINNED_GUDGEON]: 'Vitfenad sandkrypare',
            [KnownTaxonomyType.ZOPE]: 'Faren',
            [KnownTaxonomyType.OTHER]: 'Andra',
        },
        taxonomyTypeProperty: {
            [TaxonomyTypePropertyName.ADIPOSE_FIN_CUT]: {
                name: 'fettfinsnitt',
                bulkName: 'fettfinsnitt',
                estimatedName: 'fettsnitt',
            },
            [TaxonomyTypePropertyName.ADIPOSE_FIN]: {
                name: 'fettfena',
                bulkName: 'fettfena',
                estimatedName: 'fettfena',
            },
            [TaxonomyTypePropertyName.CATCH_AND_RELEASE]: {
                name: 'jag släppte tillbaka fisken',
                bulkName: 'jag släppte tillbaka fisken',
                estimatedName: 'jag släppte tillbaka fisken',
            },
            [TaxonomyTypePropertyName.LENGTH]: {
                name: 'längd',
                bulkName: 'total längd',
                estimatedName: 'ungefärlig längd',
            },
            // these three properties are only received for data validation,
            // and shouldn't be displayed to the user - which is why they're empty
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_1]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_2]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.MUST_RELEASE_NON_ADIPOSE_FIN]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.WEIGHT]: {
                name: 'vikt',
                bulkName: 'totalvikt',
                estimatedName: 'ungefärlig vikt',
            },
        },
        map: {
            searchBar: {
                clearResults: 'Rensa sökresultat',
                label: 'Sök',
                noResultsFound: 'Inga sökresultat hittades',
                placeholder: 'Sök...',
                resultsFound: 'Hittade {{ count }} sökresultat',
                requestError: 'Sökningen kan inte utföras. Bakgrundssystemet kan inte nås.',
                result: {
                    region: 'Region',
                    subregion: 'Del',
                    municipality: 'Kommun',
                    placeType: 'Typ',
                    placeTypeDescription: 'Beskriving',
                    placeTypeCategory: 'Kategori',
                    placeTypeGroup: 'Grupp',
                    placeTypeSubgroup: 'Delgrupp',
                },
            },
            layers: {
                [MapLayer.ACTIVITY]: { name: 'Aktiviteter', settings: {} },
                [MapLayer.CATCH]: { name: 'Fångster', settings: {} },
                [MapLayer.WFS_FISHING_RESTRICTIONS]: { name: 'Fiskerestriktioner i närheten', settings: {} },
                [MapLayer.HIGHLIGHT_LAYER]: { name: 'Höjdpunkter', settings: {} },
                [MapLayer.POINT_OF_INTEREST]: { name: 'Intressepunkter', settings: {} },
                [MapLayer.POSITION]: { name: 'Position', settings: {} },
                [MapLayer.ROUTE]: { name: 'Rutt', settings: {} },
                [MapLayer.ORTHOPHOTO_LAYER]: { name: 'Ortofoto', settings: {} },
                [MapLayer.VECTOR_LAYER]: { name: 'Allmän', settings: {} },
                [MapLayer.TERRAIN_LAYER]: { name: 'Terräng', settings: {} },
                [MapLayer.DEPTH_CURVES]: { name: 'Djupkurvor', settings: {} },
                [MapLayer.WMS_FISHING_RESTRICTIONS]: {
                    name: 'Fiskerestriktioner',
                    settings: {
                        [LayerCommonSettingName.FROM_DATE]: 'Från',
                        [LayerCommonSettingName.TO_DATE]: 'Till',
                        deviatingRestrictionsOnly: 'Endast onormala fångststorlekar och viloperioder',
                    },
                },
            },
            settings: {
                mapLayer: {
                    title: 'Kartlagerinställningar',
                },
            },
        },
    },
};

export default translations;
