import { PageResponse } from '@oma-kala-shared/core/model';
import { set } from 'lodash';
import { useState, useEffect } from 'react';

interface UsePaginationProps<T> {
    initialPageSize?: number;
}

export const usePagination = <T>({ initialPageSize = 10 }: UsePaginationProps<T>) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [totalItems, setTotalItems] = useState(0);
    const [items, setItems] = useState<T[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [apiResponse, setApiResponse] = useState<PageResponse<T> | null>(null);

    useEffect(() => {
        if (!apiResponse) {
            return;
        }

        const { content, totalElements, totalPages } = apiResponse;
        setItems(content);
        setTotalPages(totalPages);
        setTotalItems(totalElements);
    }, [currentPage, pageSize, apiResponse]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to the first page when page size changes
    };

    return {
        currentPage,
        pageSize,
        totalItems,
        items,
        totalPages,
        setApiResponse,
        handlePageChange,
        handlePageSizeChange,
        setCurrentPage,
    };
};
