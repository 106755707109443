/* eslint-disable require-jsdoc */
import { Backdrop, CircularProgress, Paper } from '@mui/material';

import React from 'react';
import UserSearchPanel from './UserSearchPanel';
import UserSearchResult from './UserSearchResult';
import { useUserManagementViewContext, ManagementViewContextProvider } from './ManagementViewContext';

/**
 * @return {JSX.Element}
 */
const UserManagementContent = () => {
    const { showBackdrop, roles } = useUserManagementViewContext();

    if (roles.length === 0) {
        return <></>;
    }

    return (
        <Paper sx={{ mb: 2, p: 2, position: 'relative', display: 'block' }}>
            <UserSearchPanel />

            <UserSearchResult />

            <Backdrop open={showBackdrop} sx={{ position: 'absolute' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Paper>
    );
};

const UserManagementView = () => (
    <ManagementViewContextProvider>
        <UserManagementContent />
    </ManagementViewContextProvider>
);

export default UserManagementView;
