import { Button, CircularProgress, Grid, Paper, TextField } from '@mui/material';
import { FeedbackRequest, UserData } from '@oma-kala-shared/core/model';
import React, { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import { useSyncToken } from 'app/hooks';
import { setErrorMessage, setSuccessMessage } from 'app/state/message/messageSlice';
import { sendFeedback } from 'app/state/thunks';
import { selectLoggedInUserData } from 'app/state/user/userSlice';

/**
 * Create a default feedback request for the given user data
 * @param {UserData | null} userData
 * @return {FeedbackRequest}
 */
function createDefaultState(userData: UserData | null): FeedbackRequest {
    return {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        message: '',
    };
}

/**
 * @return {JSX.Element}
 */
export default function FeedbackSection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userData: UserData | null = useSelector(selectLoggedInUserData);
    const [state, setState] = useState<FeedbackRequest>(createDefaultState(userData));
    const [captcha, setCaptcha] = useState<string>('');
    const [canSend, setCanSend] = useState<boolean>(false);

    const [syncToken, loading] = useSyncToken({
        onSuccess: () => {
            dispatch(setSuccessMessage(t('feedback.successMessage')));
            navigate(-1); // Navigate to previous page
        },
        onError: message => {
            dispatch(setErrorMessage(t('feedback.errorMessage', { message: message })));
        },
    });

    const onValueChanged = (key: keyof FeedbackRequest) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState(prev => ({ ...prev, [key]: e.target.value }));
    };

    const onCaptchaValueChanged = () => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCaptcha(e.target.value);
    };

    useEffect(() => {
        if (loading) {
            setCanSend(false);
        } else {
            setCanSend(userData || validateCaptcha(captcha, false));
        }
    }, [loading, captcha]);

    const handleSubmit = () => {
        dispatch(sendFeedback(syncToken, state));
    };

    useLayoutEffect(() => {
        if (!userData) {
            loadCaptchaEnginge(6);
        }
    }, []);

    const customStyleInput = {
        borderColor: 'white',
        '& .MuiFormLabel-root': {
            color: 'white',
            '& .Mui-focused': {
                borderColor: 'white',
            },
        },
        '& .Mui-focused': {
            color: 'white',
            borderColor: 'white',
        },
        '& .MuiInputBase-root': {
            color: 'white',
            '& .Mui-focused': {
                borderColor: 'white',
            },
            '> fieldset': {
                borderColor: 'white',
                '&:hover': {
                    borderColor: 'white',
                },
            },
        },
        '&:hover': {
            borderColor: 'white',
        },
    };

    return (
        <div style={{ color: 'white', margin: '30px 0' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                sx={theme => {
                                    return customStyleInput;
                                }}
                                id="firstName"
                                fullWidth
                                autoComplete="given-name"
                                label={t('common.firstName')}
                                value={state.firstName}
                                onChange={onValueChanged('firstName')}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={theme => {
                                    return customStyleInput;
                                }}
                                id="lastName"
                                fullWidth
                                autoComplete="family-name"
                                label={t('common.lastName')}
                                value={state.lastName}
                                onChange={onValueChanged('lastName')}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={theme => {
                                    return customStyleInput;
                                }}
                                id="email"
                                fullWidth
                                autoComplete="email"
                                label={t('common.email')}
                                value={state.email}
                                onChange={onValueChanged('email')}
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        sx={theme => {
                            return customStyleInput;
                        }}
                        id="feedback"
                        fullWidth
                        multiline
                        rows={7}
                        variant="outlined"
                        label={t('feedback.feedback')}
                        value={state.message}
                        onChange={onValueChanged('message')}
                        disabled={loading}
                    />
                </Grid>
            </Grid>

            {userData ? (
                <></>
            ) : (
                <Grid container spacing={3} sx={{ marginTop: 3 }}>
                    <Grid item xs={12}>
                        <div style={{ marginBottom: '24px' }}>{t('feedback.captchaMessage')}</div>
                        <LoadCanvasTemplateNoReload />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            sx={theme => {
                                return customStyleInput;
                            }}
                            id="captcha"
                            autoComplete="email"
                            label={t('feedback.captcha')}
                            onChange={onCaptchaValueChanged()}
                            disabled={loading}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid container spacing={3} justifyContent="left" sx={{ marginTop: 3 }}>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#0033A0',
                            color: 'white',
                        }}
                        fullWidth
                        onClick={handleSubmit}
                        disabled={!canSend}
                    >
                        {t('common.send')}
                        {loading && <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
