import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, PageResponse, PagingRequest, UserData } from '@oma-kala-shared/core/model';
import { RequestStatus } from 'app/model';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';

/**
 * Search declarations
 * @param {string} syncToken
 * @param {string} email
 * @return {Promise<ContentResponse<DeclarationDetails>>}
 */
export const searchUserByEmail =
    (syncToken: string, email: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<UserData> = await service.getUserByEmail(email);

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        resource: response.content,
                    },
                })
            );
        } else {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: 'N/A',
                    },
                })
            );
        }
    };

export const searchUserInRole =
    (syncToken: string, roleId: string, pageRequest: PagingRequest): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<PageResponse<UserData>> = await service.getUsersByRole(roleId, pageRequest);

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        resource: response.content,
                    },
                })
            );
        } else {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: 'N/A',
                    },
                })
            );
        }
    };
