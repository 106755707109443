import React, { useState } from 'react';

import styled from '@emotion/styled';
import { CardMedia, Grid, Typography } from '@mui/material';
import { filterCmsItemsByTag, getLocalizedLinkTag } from '@oma-kala-shared/core/logic';
import { CMSItem, LocalizedTags } from '@oma-kala-shared/core/model';
import { first } from 'lodash';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCmsElements } from 'app/state/cms/cmsSlice';
import { landingPurple } from 'app/theme';

import { CollapseSectionWrapper } from './CollapseSectionWrapper';
import FeedbackSection from './FeedbackSection';
import LandingWrapper from './LandingWrapper';
import NewsSection from './NewsSection';
import { StoreVariantEnum, getStoreQrCodeImage } from 'app/logic/ImageService';
import { getDefaultTagsets } from 'app/logic/CmsService';
import StoreImageButton from 'app/components/app-links/StoreImageButton';
import StoreQrCode from 'app/components/app-links/StoreQrCode';

/**
 * @return {JSX.Element}
 */
export default function LandingPage() {
    const { t, i18n } = useTranslation();
    const [openNews, setOpenNews] = useState<boolean>(false);
    const [openFeedback, setOpenFeedback] = useState<boolean>(false);

    // Dynamic content from CMS to home page
    const elements: CMSItem[] = useSelector(selectCmsElements);
    const tagset: LocalizedTags = getDefaultTagsets();
    const cmsItem: CMSItem | undefined = first(filterCmsItemsByTag(elements, getLocalizedLinkTag(i18n.language, tagset)));

    const storeImageButtonStyle = { height: '60px' };
    const storeQrCodeStyle = { width: '100%', maxWidth: 400, padding: '20px' };

    return (
        <LandingWrapper
            visibleLoginButton={true}
            visibleBackButton={false}
            main={
                <MainWrapper>
                    <SplashMessage>
                        <Grid container alignItems={'center'} spacing={10}>
                            {cmsItem && (
                                <Grid item xs={12} lg={6}>
                                    <>
                                        <Typography
                                            fontWeight={'bold'}
                                            sx={{ marginBottom: 2 }}
                                            lineHeight={{ xs: 1 }}
                                            fontSize={{ lg: 72, xs: 42 }}
                                        >
                                            <span dangerouslySetInnerHTML={{ __html: cmsItem.title.rendered }}></span>
                                        </Typography>
                                        <Typography sx={{ marginBottom: 6 }} fontSize={{ lg: 22, sm: 22 }}>
                                            <span dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }}></span>
                                        </Typography>
                                    </>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={6}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    >
                                        <StoreImageButton style={storeImageButtonStyle} marketplace={StoreVariantEnum.PLAY_STORE} />
                                        <StoreQrCode style={storeQrCodeStyle} marketplace={StoreVariantEnum.PLAY_STORE} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    >
                                        <StoreImageButton style={storeImageButtonStyle} marketplace={StoreVariantEnum.APP_STORE} />
                                        <StoreQrCode style={storeQrCodeStyle} marketplace={StoreVariantEnum.APP_STORE} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SplashMessage>
                </MainWrapper>
            }
            content={
                <ContentWrapper>
                    <CollapseSectionWrapper title={t('news.title')} isOpen={openNews} handleSectionToggle={() => setOpenNews(!openNews)}>
                        <NewsSection />
                    </CollapseSectionWrapper>
                    <CollapseSectionWrapper
                        title={t('feedback.title')}
                        isOpen={openFeedback}
                        handleSectionToggle={() => setOpenFeedback(!openFeedback)}
                        background="#29374D"
                    >
                        <FeedbackSection />
                    </CollapseSectionWrapper>
                </ContentWrapper>
            }
        />
    );
}

const MainWrapper = styled('div')({
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(30, 41, 59, 0) 0%, #1E293B 100%)',
});

const ContentWrapper = styled('div')({
    height: '100%',
    width: '100%',
    backgroundColor: landingPurple,
});

const SplashMessage = styled('div')({
    color: 'white',
    padding: '80px',
    fontSize: '24px',
    height: '100%',
    minHeight: '75vh',
    display: 'flex',
});
