import React from 'react';
import UserSearchFilter from 'app/components/user/UserSearchFilter';
import { useUserManagementViewContext, FilterCriteria } from './ManagementViewContext';
/**
 * @return {JSX.Element}
 */
const UserSearchPanel = () => {
    const { setFilterCriteria, roles } = useUserManagementViewContext();

    const handleSearch = (criteria: FilterCriteria) => {
        setFilterCriteria(criteria);
    };

    return <UserSearchFilter onSearch={handleSearch} roles={roles} />;
};

export default UserSearchPanel;
